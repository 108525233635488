.container {
  background-color: $white;
  width: 416px;
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 1px 5px rgba(189, 189, 189, 0.45);
  border-radius: 4px;
  margin-bottom: 24px;

  &--start-page {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: $mobile-width) {
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    top: 0;
    left: 0;
    transform: none;
    position: static;
    height: 100%;
  }
}
