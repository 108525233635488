.enter {
  padding: 0 48px 64px;

  &--sms-code {
    padding-top: 48px;
    padding-bottom: 40px;
  }

  &--new-password {
    padding-top: 48px;
    padding-bottom: 40px;

    .enter__slogan {
      margin-bottom: 32px;
    }

    .enter__submit-new-password {
      margin-bottom: 0;
    }
  }

  &__slogan {
    color: $dark-black;
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 16px;
    text-align: center;
  }

  &__details-slogan {
    color: $charcoal;
    font-size: 16px;
    text-align: center;
    margin-bottom: 32px;
  }

  &__error {
    display: none;
  }

  &__input {
    outline: none;
    background-color: $white;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 14px 16px;
    font-size: 16px;
    color: $black;
    width: 100%;
  }

  &__input::placeholder {
    color: $charcoal;
  }

  &__input:focus {
    border-color: $black;
  }

  &__item {
    position: relative;

    &--login,
    &--new-password {
      margin-bottom: 16px;
    }

    &--password,
    &--phone-to-remind,
    &--sms-code,
    &--repeat-new-password {
      margin-bottom: 32px;
    }

    &--error {
      .enter__input {
        border-color: $orange;
      }

      .enter__input:focus {
        border-color: $orange;
      }

      .enter__error {
        display: inline;
        color: $orange;
        font-size: 12px;
        position: absolute;
        right: 0;
        bottom: -19px;
      }
    }
  }

  &__submit-enter,
  &__submit-remind-button,
  &__submit-sms-code,
  &__submit-new-password {
    @include reset-button;

    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 100%;
    background-color: $orange;
    padding: 15px;
    font-size: 16px;
    color: $white;
    margin-bottom: 32px;
    border-radius: 50px;
    transition: all 0.5s ease-out 0.5s;
  }

  &__submit-enter:hover,
  &__submit-enter:focus,
  &__submit-remind-button:hover,
  &__submit-remind-button:focus,
  &__submit-sms-code:hover,
  &__submit-sms-code:focus,
  &__submit-new-password:hover,
  &__submit-new-password:focus {
    background-color: $red;
  }

  &__submit-enter:disabled,
  &__submit-remind-button:disabled,
  &__submit-sms-code:disabled,
  &__submit-new-password:disabled {
    color: $half-white;
    cursor: default;
  }

  &__submit-enter:disabled:hover,
  &__submit-enter:disabled:focus,
  &__submit-remind-button:disabled:hover,
  &__submit-remind-button:disabled:focus,
  &__submit-sms-code:disabled:hover,
  &__submit-sms-code:disabled:focus,
  &__submit-new-password:disabled:hover,
  &__submit-new-password:disabled:focus {
    background-color: $orange;
  }

  &__password-update-link,
  &__register-link,
  &__login-link {
    color: $orange;
    transition: all 0.5s ease-out 0.5s;
    font-size: 16px;
  }

  &__password-update-link:hover,
  &__password-update-link:focus,
  &__register-link:hover,
  &__register-link:focus,
  &__login-link:hover,
  &__login-link:focus {
    color: $red;
  }

  &__no-profile {
    font-size: 16px;
    text-align: center;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  &__remember-label {
    position: relative;
    font-size: 16px;
    padding-left: 32px;
    cursor: pointer;
  }

  &__remember-label::before {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid $pale-charcoal;
    left: 4px;
    top: 0;
  }

  &__remember-input:checked + &__remember-label::after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-image: url("../img/icons/icon-white-check.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    top: 4px;
    left: 8px;
  }

  &__remember-input:checked + &__remember-label::before {
    background-color: $orange;
    border-color: $orange;
  }

  &__back-to-enter {
    font-size: 16px;
    text-align: center;
  }

  &__number-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    margin-bottom: 32px;
  }

  &__repeat-details {
    color: $charcoal;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
  }

  &__timer {
    font-weight: 700;
  }

  @media (max-width: $tablet-width) {
    &__item {
      &--new-password,
      &--repeat-new-password,
      &--sms-code {
        .enter__input {
          box-shadow: inset 0 4px 4px rgba(189, 189, 189, 0.25);
        }
      }
    }
  }

  @media (max-width: $mobile-width) {
    padding: 0 19px 107px;

    &--sms-code {
      padding-bottom: 202px;
    }

    &__number-slogan {
      text-align: center;
    }
  }
}
