.registration__user-types-switcher {
  display: flex;
  margin-bottom: 1rem;

  .phys {
    label {
      border-radius: 15px 0 0 15px;
      background-color: #f0f0f0;
      border-color: white;
    }

    input[type=radio]:checked + label {
      background: #f57e00;
      color: white;
    }
  }

  .legal {
    label {
      border-radius: 0 15px 15px 0;
      border-color: white;
      background-color: #f0f0f0;
    }

    input[type=radio]:checked + label {
      background: #f57e00;
      color: white;
    }
  }
}

.registration__user-types-switcher.profile-form__item--error {
  .phys {
    label {
      border-color: #f57e00;
    }
  }

  .legal {
    label {
      border: 1px solid #f57e00;
    }
  }
}

.registration__user-type {
  float: left;
  display: inline-block;

  input[type=radio] {
    display: none;
  }

  label {
    display: inline-block;
    padding: 0px 15px;
    line-height: 34px;
    border: 1px solid #999;
    border-right: none;
    cursor: pointer;
    user-select: none;
  }
}
