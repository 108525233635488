//.person-registering,
.order {
  &__upload-details {
    line-height: 20px;
    color: $charcoal;
    padding-left: 3px;
  }

  &__upload-slogan {
    color: $orange;
    font-size: 16px;
    transition: all 0.5s ease-out 0.5s;
  }

  &__upload-label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__clip-icon {
    margin-left: 3px;
    margin-right: 9px;
  }

  &__upload-input {
    overflow: hidden;
    outline: none;
    opacity: 0;
    position: absolute;
    cursor: pointer;
    padding-left: 238px;
  }

  &__upload-input:hover + &__upload-label &__upload-slogan {
    color: $red;
  }

  &__upload-item {
    margin-bottom: 8px;
  }

  &__upload-wrap {
    padding-bottom: 3px;
    margin-bottom: 29px;
    position: relative;

    &--hidden {
      display: none;
    }

    &--company {
      margin-top: 16px;
      margin-bottom: 0;
    }

    &--error {
      border: 1px solid $orange;
      border-radius: 3px;
    }
  }

  &__error {
    display: inline;
    color: $orange;
    font-size: 12px;
    position: absolute;
    right: 0;
    bottom: -22px;
  }

  &__uploded-images-list {
    @include reset-list;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__preview-item {
    margin-right: 16px;
    margin-top: 16px;
    position: relative;
    border: 1px dashed $orange;
    border-radius: 3px;
    width: 100px;
    height: 100px;
  }

  &__preview-item::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32));
  }

  &__preview-image {
    width: 100px;
    height: 100px;
  }

  &__preview-close-button {
    @include reset-button;

    position: absolute;
    top: 9px;
    right: 9px;
    width: 15px;
    height: 15px;
  }

  &__preview-close-button::before,
  &__preview-close-button::after {
    position: absolute;
    content: "";
    width: 15px;
    height: 3px;
    background-color: $white;
    left: 1px;
    top: 5px;
  }

  &__preview-close-button::before {
    transform: rotate(-45deg);
  }

  &__preview-close-button::after {
    transform: rotate(45deg);
  }
}
