.order {
  background-color: $very-pale-grey;
  border-bottom: 1px solid $very-pale-grey;

  &__title-wrap {
    background-color: $white;
  }

  &__title {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: $dark-black;
    margin: 0;
    padding: 40px 0;
  }

  &__container-img {
    max-height: 120px;
    overflow-y: auto;
  }

  &__image {
    width: 108px;
    height: 108px;
    max-width: 108px;
    max-height: 108px;
    flex: 1;
    margin-right: 10px;
    background: #F0F0EE url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 22.5C20.4853 22.5 22.5 20.4853 22.5 18C22.5 15.5147 20.4853 13.5 18 13.5C15.5147 13.5 13.5 15.5147 13.5 18C13.5 20.4853 15.5147 22.5 18 22.5Z' fill='white'/%3E%3Cpath d='M30 6H25.245L23.385 3.975C23.1055 3.66809 22.765 3.42286 22.3853 3.25498C22.0056 3.0871 21.5951 3.00026 21.18 3H14.82C13.98 3 13.17 3.36 12.6 3.975L10.755 6H6C4.35 6 3 7.35 3 9V27C3 28.65 4.35 30 6 30H30C31.65 30 33 28.65 33 27V9C33 7.35 31.65 6 30 6ZM18 25.5C13.86 25.5 10.5 22.14 10.5 18C10.5 13.86 13.86 10.5 18 10.5C22.14 10.5 25.5 13.86 25.5 18C25.5 22.14 22.14 25.5 18 25.5Z' fill='white'/%3E%3C/svg%3E%0A") center/50% no-repeat;
    object-fit: cover;
  }

  &__slogan {
    font-size: 19px;
    color: $charcoal;
    text-align: center;
    margin: 32px 0;
  }

  &__wrap {
    width: 416px;
    margin: 0 auto;
    background-color: $white;
    box-shadow: 0 1px 5px rgba(189, 189, 189, 0.35);
    border-radius: 4px;
    padding: 32px 40px 40px;
    margin-bottom: 64px;

    &--rent {
      .order__chosen-address {
        margin-bottom: 32px;
      }

      .order__fixed-date-end {
        margin-bottom: 32px;
      }

      .order__date-item--last-date {
        margin-bottom: 32px;
      }
    }
  }

  &__chosen-slogan {
    font-size: 16px;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 8px;
  }

  &__chosen-address {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 16px;
  }

  &__box-number-select {
    width: 100%;
    outline: none;
    cursor: pointer;
    display: block;
    appearance: none;
    box-shadow: inset 0 28px 20px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid $light-charcoal;
    padding: 8px 25px 7px 12px;
    background-color: $white;

    color: $black;
    line-height: 24px;
    background-image: url("../img/icons/icon-double-select-arrows.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: top 10px right 10px;
    font-weight: 700;
  }

  &__date-slogan {
    font-size: 16px;
    margin-bottom: 8px;

    &--start {
      margin-top: 32px;
    }
  }

  &__date-item {
    &--start-date {
      margin-bottom: 16px;
    }

    &--last-date {
      margin-bottom: 8px;
    }

    &--hidden {
      display: none;
    }
  }

  &__date-input {
    border: 1px solid $light-charcoal;
    border-radius: 4px;
    background-color: $white;
    padding: 14px 12px;
    font-size: 16px;
    width: 100%;
    outline: none;
  }

  &__date-input::placeholder {
    color: $pale-charcoal;
  }

  &__date-input:focus {
    border-color: $black;
  }

  &__term-note {
    font-style: italic;
    line-height: 20px;
    color: $charcoal;
    margin-bottom: 8px;
  }

  &__terms-list {
    @include reset-list;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
  }

  &__term-button {
    @include reset-button;

    color: $orange;
    font-size: 16px;
    padding-bottom: 1.5px;
    border-bottom: 1px dashed $orange;
    transition: color 0.5s ease-out 0.5s, border-color 0.5s ease-out 0.5s;
  }

  &__term-button:hover,
  &__term-button:focus {
    color: $red;
    border-color: $red;
  }

  &__terms-item {
    margin-bottom: 15px;
  }

  &__terms-item:not(:last-child) {
    margin-right: 16px;
  }

  &__rates-list {
    @include reset-list;
    font-size: 16px;
    margin-bottom: 32px;
  }

  &__rate-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__promo-code-input {
    width: 222px;
    border: 1px solid $light-charcoal;
    background-color: $white;
    border-radius: 4px;
    padding: 8px 66px 6px 12px;
    font-size: 16px;
    line-height: 24px;
    outline: none;
  }

  &__promo-code-input::placeholder {
    color: $pale-charcoal;
  }

  &__confirm-promo-code {
    @include reset-button;

    border-radius: 0 4px 4px 0;
    width: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $light-charcoal;
    background-color: $pale-grey;
    padding: 10px 4px 12px;
    position: absolute;
    top: 0;
    left: 168px;
    transition: background-color 0.5s ease-out 0.5s;
  }

  &__confirm-promo-code:hover,
  &__confirm-promo-code:focus {
    background-color: rgba(0, 0, 0, 0.12);
  }

  &__code-error,
  &__code-success {
    display: none;
  }

  &__code {
    position: relative;
    margin-bottom: 32px;

    &--error {
      .order__code-error {
        display: inline-block;
        vertical-align: top;
        color: $orange;
        position: absolute;
        bottom: -22px;
        left: 2px;
        font-size: 16px;
      }
    }

    &--success {
      .order__confirm-promo-code {
        display: none;
      }

      .order__code-success {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 54px;
        height: 38px;
        position: absolute;
        top: 1px;
        left: 167px;
        border-left: 1px solid $light-charcoal;
        background-color: $pale-grey;
        border-radius: 0 5px 5px 0;
      }
    }
  }

  &__confirm-button-text {
    text-transform: uppercase;
    color: $orange;
  }

  &__result-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $charcoal;
    font-size: 16px;
    margin-bottom: 8px;

    &--hidden {
      display: none;
    }
  }

  &__order-total-sum-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 19px;
    margin-bottom: 32px;
  }

  &__pay-button {
    @include reset-button;

    width: 100%;
    background-color: $orange;
    border-radius: 30px;
    color: $white;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    padding: 15px;
    font-size: 16px;
    transition: background-color 0.5s ease-out 0.5s;
  }

  &__pay-button:hover,
  &__pay-button:focus {
    background-color: $red;
  }

  &__pay-button:disabled {
    color: $half-white;
    cursor: default;
  }

  &__pay-button:disabled:hover,
  &__pay-button:disabled:focus {
    background-color: $orange;
  }

  &__fixed-date {
    font-size: 16px;
    margin-bottom: 16px;

    &--hidden {
      display: none;
    }
  }

  &__fixed-date-start,
  &__fixed-date-end {
    font-size: 16px;
    margin-bottom: 16px;
  }

  &__box-details {
    color: $charcoal;
    font-style: italic;
    line-height: 20px;
    margin-bottom: 32px;
    margin-top: 8px;

    &--hidden {
      display: none;
    }
  }

  &__cashback {
    margin-bottom: 32px;

    &--hidden {
      display: none;
    }
  }

  &__cashback-wrap {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 19px;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__cashback-notice {
    width: 240px;
    line-height: 20px;
    color: $charcoal;
  }

  &__keys-input {
    width: 100%;
    background-color: $white;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    box-shadow: inset 0 28px 20px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    border: 1px solid $light-charcoal;
    color: $black;
    padding: 8px 12px 6px;
  }

  &__keys-input::placeholder {
    color: $black;
  }

  &__keys-item {
    margin-bottom: 8px;
  }

  &__keys-notice {
    line-height: 20px;
    font-style: italic;
    color: $charcoal;
    margin-bottom: 32px;
  }

  &__keys-slogan {
    font-size: 16px;
    margin-bottom: 8px;
  }

  &__notes {

    &__checkbox-label {
      position: relative;
      padding-left: 32px;
      cursor: pointer;
      font-size: 16px;
    }

    &__checkbox-label::before {
      position: absolute;
      content: "";
      width: 18px;
      height: 18px;
      border: 2px solid $pale-charcoal;
      border-radius: 2px;
      top: 1px;
      left: 3px;
      background-color: $white;
    }

    &__checkbox-input:checked + &__checkbox-label::before {
      background-color: $orange;
      border-color: $orange;
    }

    &__checkbox-input:checked + &__checkbox-label::after {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      background-image: url("../img/icons/icon-white-check.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      top: 5px;
      left: 7px;
    }

    &__textarea {
      resize: none;
      height: 50px;
      width: 100%;
      padding: 1rem;
      border: 1px solid #ccc;
    }
  }

  @media (max-width: $mobile-width) {
    &__wrap {
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
      margin-bottom: 112px;
    }

    &__content {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}
