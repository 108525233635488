.smart-booking {
  background-color: $white;
  width: 100%;
  padding: 32px 32px 40px;

  &__name {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    color: $dark-black;
    margin-bottom: 8px;
  }

  &__notifications {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
                ". ."
                ". .";
  }

  &__notification {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem;
    flex-direction: column;
  }

  &__boxes {
    border-top: 1px solid #eee;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &__title {
      margin: 0 0 8px;
      font-size: 19px;
      font-weight: 400;
    }

    &__link--btn {
      display: inline-block;
      vertical-align: top;
      padding: 12px 20px;
      font-size: 16px;
      color: white;
      border-radius: 30px;
      background-color: #f57e00;
      -webkit-transition: background-color .5s ease-out .5s;
      transition: background-color .5s ease-out .5s;
    }
  }

  &__item-wrap {
    padding: 32px;
    border-bottom: 1px solid $very-light-grey;
  }

  &__back-link {
    display: none;
  }

  @media (max-width: $tablet-width) {
    &__container {
      padding-left: 0;
      padding-right: 0;
      margin-top: 16px;
    }

    &__back-link {
      display: flex;
      align-items: center;
      color: $orange;
      font-size: 19px;
      margin-bottom: 31px;
    }

    &__back-icon {
      margin-right: 13px;
    }

    &__item-wrap {
      padding: 18px 24px 30px;
    }
  }

  @media (max-width: $mobile-width) {
    &__item-wrap {
      padding: 16px 20px 32px;
    }
  }


  &__title {
    font-size: 19px;
    line-height: 24px;
    color: $black;
    margin: 0;
  }

  &__title-wrap {
    margin-bottom: 24px;
  }

  &__slogan {
    font-size: 14px;
    line-height: 20px;
    color: $charcoal;
    margin-bottom: 8px;
  }

  &__address {
    font-size: 16px;
  }

  &__details {
    display: flex;
    margin-bottom: 32px;
  }

  &__details--border {
    padding: 1rem;
    margin: 1rem 0;
    border: 2px solid #f57e00;
    border-radius: 8px;

    span {
      font-size: 16px;
      color: #f57e00;
    }
  }


  &__size-details {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

  &__item {
    &--properties {
      flex-shrink: 0;
      margin-right: 47px;
    }

    &--address {
      width: 269px;
      margin-right: 42px;
    }
  }

  &__link {
    display: inline-block;
    vertical-align: top;
    padding: 12px 20px;
    font-size: 16px;
    color: $black;
    border-radius: 30px;
    background-color: $pale-grey;
    transition: background-color 0.5s ease-out 0.5s;

    &--prolong {
      background-color: $orange;
      color: $white;
    }
  }

  &__link:hover,
  &__link:focus {
    background-color: $dark-pale-grey;
  }

  &__link--prolong:hover,
  &__link--prolong:focus {
    background-color: $dark-orange;
  }

  &__link:not(:last-of-type) {
    margin-right: 8px;
  }

  &__links-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
  }

  &__terms-wrap {
    font-size: 16px;
  }

  &__details-item-wrap {
    display: flex;
    align-items: center;
    position: relative;
  }

  @media (max-width: $tablet-width) {
    display: flex;
    flex-direction: column;
    padding: 32px 24px 39px;

    &__title-wrap {
      margin-bottom: 16px;
    }

    &__details {
      flex-direction: column;
      margin-bottom: 0;
      order: 2;
    }

    &__title {
      order: -1;
    }

    &__links-wrap {
      order: 0;
    }

    &__item {
      &--properties {
        margin-right: 0;
        order: 0;
      }

      &--address {
        margin-right: 0;
        order: -1;
        width: 505px;
        margin-bottom: 34px;
      }

      &--terms {
        display: none;
      }
    }

    &__terms-wrap {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: $mobile-width) {
    padding: 32px 20px 40px;


    &__links-wrap {
      flex-direction: column;
      align-items: flex-start;
    }

    &__link:not(:last-of-type) {
      margin-right: 0;
      margin-bottom: 16px;
    }

    &__item {
      &--address {
        width: auto;
      }

      &--terms {
        left: 20px;
      }
    }

    &__terms-wrap {
      flex-wrap: wrap;
      padding-right: 16px;
    }
  }

  &__notes {
    &__checkbox-label {
      position: relative;
      padding-left: 32px;
      cursor: pointer;
      font-size: 16px;
    }

    &__checkbox-label::before {
      position: absolute;
      content: "";
      width: 18px;
      height: 18px;
      border: 2px solid $pale-charcoal;
      border-radius: 2px;
      top: 1px;
      left: 3px;
      background-color: $white;
    }

    &__checkbox-input:checked + &__checkbox-label::before {
      background-color: $orange;
      border-color: $orange;
    }

    &__checkbox-input:checked + &__checkbox-label::after {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      background-image: url("../img/icons/icon-white-check.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      top: 5px;
      left: 7px;
    }

  }
}

