.description {
  margin-bottom: 96px;

  &--no-requisites {
    .description__no-requisites-attention {
      display: block;
      padding: 5px 16px;
      border-left: 2px solid $orange;
      margin-bottom: 16px;
    }

    .description__slogan {
      margin-bottom: 16px;
    }
  }

  &--start {
    .address__item {
      width: 100%;
      max-width: none;
      min-width: 0;
      margin-right: 0;
      margin-bottom: 15px;
    }

    .address__details {
      line-height: 16px;
    }

    .address__item-wrap {
      margin-bottom: 5px;
    }

    .description__wrap {
      flex-direction: column;
      margin-bottom: 14px;
    }

    .description__item {
      &--box-size {
        margin-right: 0;
        margin-bottom: 13px;
      }
    }
  }

  &__item-slogan {
    font-size: 16px;
    font-weight: 700;
    position: relative;
    display: inline-block;
    vertical-align: top;

    &--address {
      margin-bottom: 16px;
    }

    &--box-size,
    &--box-scope,
    &--box-number {
      margin-bottom: 8px;
    }
  }

  &__item-slogan::after {
    position: absolute;
    top: 3px;
    right: 6px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
  }

  &__item-slogan--box-size::after {
    content: "2";
  }

  &__item-slogan--box-scope::after {
    content: "3";
  }

  &__valiability-label {
    position: relative;
    padding-left: 31px;
    font-size: 16px;
  }

  &__valiability-label::before {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid $pale-charcoal;
    left: 3px;
    top: 0;
  }

  &__valiable-checkbox:checked + .description__valiability-label::before {
    border-color: $orange;
    background-color: $orange;
  }

  &__valiable-checkbox:checked + .description__valiability-label::after {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("../img/icons/icon-white-check.svg");
    left: 6px;
    top: 4px;
  }

  &__valiable-variants {
    margin-bottom: 32px;
  }

  &__slogan-no-requisites {
    font-size: 16px;
    color: $dark-red;
  }

  &__add-requisites {
    color: $orange;
    font-size: 16px;
  }

  &__no-requisites-attention {
    display: none;
  }

  &__slogan {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 32px;
  }

  &__wrap {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &__box-size-select,
  &__scope-select {
    width: 100%;
    max-width: 560px;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $light-charcoal;
    outline: none;
    cursor: pointer;
    display: block;
    appearance: none;
    padding: 12px 30px 12px 16px;
    font-size: 15px;
    line-height: 24px;
    background-repeat: no-repeat;
    background-image: url("../img/icons/icon-down-black-arrow.svg");
    background-size: 10px 6px;
    background-position: top 21px right 21px;
  }

  &__item {
    width: 100%;

    &--box-size {
      margin-right: 20px;
    }
  }

  &__list-title-wrap {
    background-color: $white;
    padding: 40px 0;
    margin-bottom: 32px;
  }

  &__list-title {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: $dark-black;
    margin: 0;
  }

  &__start-wrap {
    background-color: $white;
    box-shadow: 0 1px 5px rgba(189, 189, 189, 0.35);
    border-radius: 4px;
    width: 416px;
    margin: 0 auto;
    padding: 32px 40px 40px;
  }

  &__show-variants {
    @include reset-button;

    font-size: 16px;
    line-height: 24px;
    background-color: $orange;
    color: $white;
    transition: background-color 0.5s ease-out 0.5s;
    padding: 12px;
    border-radius: 30px;
    width: 100%;
  }

  &__show-variants:hover,
  &__show-variants:focus {
    background-color: $red;
  }

  &__container {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (max-width: $tablet-width) {
    &__box-size-select,
    &__scope-select {
      box-shadow: inset 0 4px 4px rgba(189, 189, 189, 0.25);
    }

    &__container {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  @media (max-width: $mobile-width) {
    &--start {
      .description__wrap {
        margin-bottom: 16px;
      }
    }

    &__wrap {
      flex-direction: column;
      align-items: flex-end;
    }

    &__item {
      &--box-size {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    &__box-size-select,
    &__scope-select {
      max-width: none;
      font-size: 16px;
    }

    &__item-slogan {
      &--address {
        margin-bottom: 7px;
      }
    }

    &__start-wrap {
      max-width: 320px;
      width: 100%;
      padding: 32px 17px 40px 20px;
    }

    &__container {
      padding-left: 5.3%;
      padding-right: 5.3%;
    }
  }

  @media (max-width: 318px) {
    &__container {
      padding-left: 3%;
      padding-right: 3%;
    }

    &--start {
      .address__label {
        padding-left: 24px;
      }

      .description__start-wrap {
        padding-left: 13px;
        padding-right: 13px;
      }
    }
  }
}
