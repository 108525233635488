.modal-set {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 5px rgba(189, 189, 189, 0.45);
  border-radius: 4px;
  background-color: $white;
  width: 416px;
  padding: 48px 48px 40px;
  z-index: 5;

  &__changing-note::-webkit-scrollbar {
    width: 3px;
  }

  &__changing-note::-webkit-scrollbar-track {
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
    border-radius: 10px;
    background-color: #f9f9fd;
  }

  &__changing-note::-webkit-scrollbar-thumb {
    background-color: #f57e0096;
    border-radius: 10px;
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .25) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, .25) 50%,
      rgba(255, 255, 255, .25) 75%,
      transparent 75%,
      transparent);
  }

  &--hidden {
    display: none;
  }

  &--passport-details {
    .modal-set__modal-name {
      margin-bottom: 32px;
    }

    .modal-set__close-button {
      top: 19px;
      right: 22px;
    }
  }

  &__actual-address-wrap {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  &__same-address {
    margin-left: 201px;
    margin-top: 8px;
  }

  &__checkbox-label {
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    font-size: 15px;
    line-height: 24px;
  }

  &__checkbox-label::before {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid $pale-charcoal;
    left: 2px;
    top: 0;
  }

  &__checkbox-input:checked + .modal-set__checkbox-label::before {
    background-color: $orange;
    border-color: $orange;
  }

  &__checkbox-input:checked + .modal-set__checkbox-label::after {
    position: absolute;
    content: "";
    background-image: url("../img/icons/icon-white-check.svg");
    background-repeat: no-repeat;
    width: 12px;
    height: 11px;
    top: 2px;
    left: 5px;
    background-size: 100%;
  }

  &--ip-edit-requisites,
  &--ooo-edit-requisites {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 24px;
    width: 616px;
    padding-top: 57px;

    .modal-set__input {
      width: 320px;
      font-size: 15px;
      line-height: 24px;
    }

    .modal-set__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;

      &--actual-address {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
    }

    .modal-set__item:not(:last-of-type) {
      margin-bottom: 16px;
    }

    .modal-set__item:last-of-type {
      margin-bottom: 32px;
    }

    .modal-set__modal-name {
      margin-bottom: 31px;
    }

    .modal-set__submit {
      position: relative;
      transform: translateX(-50%);
    }

    .modal-set__close-button {
      width: 22px;
      height: 22px;
      top: 20px;
      right: 21px;
    }

    .modal-set__close-button::before,
    .modal-set__close-button::after {
      width: 24px;
    }
  }

  &--ip-edit-requisites {
    .modal-set__submit {
      width: 160px;
      left: 54%;
    }
  }

  &--ooo-edit-requisites {
    .modal-set__submit {
      width: 200px;
      left: 58%;
    }
  }

  &--sms-password {
    .modal-set__submit {
      margin-bottom: 32px;
    }

    .modal-set__close-button {
      top: 20px;
      right: 21px;
    }
  }

  &--new-password-confirmation {
    .modal-set__modal-name {
      margin-bottom: 32px;
    }
  }

  &--request-sent {
    text-align: center;
  }

  &__label {
    font-size: 15px;
  }

  &__success-text {
    font-size: 16px;
  }

  &__close-button {
    @include reset-button;

    width: 14px;
    height: 14px;
    position: absolute;
    top: 22px;
    right: 22px;
  }

  &__close-button::before,
  &__close-button::after {
    position: absolute;
    content: "";
    top: 5;
    left: -2px;
    width: 18px;
    height: 2px;
    background-color: $charcoal;
  }

  &__close-button::before {
    transform: rotate(-45deg);
  }

  &__close-button::after {
    transform: rotate(45deg);
  }

  &__modal-name {
    text-align: center;
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    color: $dark-black;
    margin-bottom: 16px;
  }

  &__slogan {
    text-align: center;
    font-size: 16px;
    margin-bottom: 16px;
  }

  &__phone-link {
    display: block;
    text-align: center;
    color: $black;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  &__details {
    color: $charcoal;
    font-size: 16px;
    text-align: center;
    margin-bottom: 16px;
  }

  &__input {
    width: 100%;
    border: 1px solid $light-charcoal;
    border-radius: 4px;
    background-color: $white;
    padding: 11px 16px;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    color: $black;
    cursor: pointer;
  }

  &__input::placeholder {
    font-size: 16px;
    line-height: 24px;
    color: $charcoal;
  }

  &__input:focus {
    border-color: $black;
  }

  &__submit {
    @include reset-button;

    border-radius: 50px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    background-color: $orange;
    transition: background-color 0.5s ease-out 0.5s;
    color: $white;
    font-size: 16px;
    line-height: 24px;
    padding: 12px;
  }

  &__submit:hover,
  &__submit:focus {
    background-color: $red;
  }

  &__submit:disabled {
    color: $half-white;
    cursor: default;
  }

  &__submit:disabled:hover,
  &__submit:disabled:focus {
    background-color: $orange;
  }

  &__error {
    display: none;

    font-size: 12px;
    line-height: 20px;
    color: $orange;
    position: absolute;
    bottom: -17px;
    right: 3px;
  }

  &__item {
    position: relative;

    &--new-phone,
    &--new-email {
      margin-bottom: 16px;
    }

    &--phone-pin-password,
    &--email-pin-password {
      margin-bottom: 32px;
    }

    &--error {
      .modal-set__input {
        border-color: $orange;
      }

      .modal-set__error {
        display: block;
      }
    }

    &--confirmed-phone {
      margin-bottom: 32px;
    }

    &--sms-password {
      margin-bottom: 32px;
    }

    &--new-password {
      margin-bottom: 16px;
    }

    &--new-password-confirmation {
      margin-bottom: 32px;
    }
  }

  &__reset-slogan,
  &__sms-reset-timer {
    color: $charcoal;
    font-size: 16px;
    text-align: center;
  }

  &__passport-pictures-list {
    @include reset-list;

    display: flex;
    flex-wrap: wrap;
    margin-bottom: 22px;
  }

  &__passport-picture-item {
    width: 100px;
    height: 100px;
    position: relative;
    margin: 0 16px 10px 0;
    border: 1px dashed $orange;
    border-radius: 3px;
  }

  &__passport-picture-item::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32));
  }

  &__remove-picture-button {
    @include reset-button;

    position: absolute;
    width: 15px;
    height: 15px;
    top: 9px;
    right: 9px;
    z-index: 2;
  }

  &__remove-picture-button::before,
  &__remove-picture-button::after {
    position: absolute;
    content: "";
    width: 14px;
    height: 3px;
    background-color: $white;
    top: 6px;
    left: 1px;
  }

  &__remove-picture-button::before {
    transform: rotate(-45deg);
  }

  &__remove-picture-button::after {
    transform: rotate(45deg);
  }

  &__upload-description {
    color: $charcoal;
    margin: 0 0 16px;
    font-size: 14px;
    line-height: 20px;
  }

  &__scan-image {
    width: 100px;
    height: 100px;
  }

  &__upload-input {
    overflow: hidden;
    outline: none;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    padding-left: 238px;
  }

  &__upload-slogan {
    font-size: 16px;
  }

  &__upload-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.5s ease-out 0.5s;
    color: $orange;
  }

  .modal-set__upload-input:hover + .modal-set__upload-label {
    color: $red;
  }

  &__clip-icon {
    margin-right: 12px;
  }

  &__upload-item {
    margin-bottom: 8px;
  }

  &__changing-note {
    font-size: 16px;
    text-align: center;
    margin-bottom: 32px;
  }

  &__sms-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__sms-slogan,
  &__this-number-sent {
    font-size: 16px;
  }

  &__this-number-sent {
    margin-bottom: 32px;
  }

  &__timer-wrap {
    &--hidden {
      display: none;
    }
  }

  &__resend-wrap {
    text-align: center;

    &--hidden {
      display: none;
    }
  }

  &__resend-link {
    color: $orange;
    transition: color 0.5s ease-out 0.5s;
    font-size: 16px;
  }

  &__resend-link:hover,
  &__resend-link:focus {
    color: $red;
  }

  @media (max-width: $mobile-width) {
    max-width: 320px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    &--ip-edit-requisites,
    &--ooo-edit-requisites {
      width: 100%;
      max-width: 100%;
      top: 0;

      .modal-set__submit {
        width: 100%;
        left: 0;
        position: static;
        transform: none;
      }

      .modal-set__item {
        flex-direction: column-reverse;
        align-items: flex-start;
      }

      .modal-set__input {
        width: 100%;
      }

      .modal-set__label {
        margin-bottom: 4px;
      }
    }

    &--sms-password {
      padding-left: 5px;
      padding-right: 5px;

      .modal-set__submit {
        width: 280px;
      }

      .modal-st__form {
        text-align: center;
      }
    }

    &__actual-address-wrap {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &__same-address {
      position: absolute;
      margin: 0;
      top: 0;
      right: 0;
    }

    &__details {
      margin-bottom: 32px;
    }

    &__sms-wrap {
      display: inline-block;
      text-align: center;
    }

    &__item {
      &--sms-password {
        width: 280px;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
}
