.page-footer {
  font-size: 16px;
  font-style: italic;
  color: $charcoal;
  background-color: $white;

  &--account {
    background-color: $very-pale-grey;
  }

  &__container {
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 10px 24px;
  }

  &__site-link {
    color: $charcoal;
  }

  &__mail-link {
    color: $charcoal;
    text-decoration: underline;
  }

  &__contract-link {
    color: $charcoal;
    margin-left: 48px;
    text-decoration: underline;
  }

  &__wrap {
    display: flex;
    align-items: center;
  }

  &__mail-wrap {
    display: flex;
    align-items: center;
  }

  &__phone-wrap {
    display: none;
  }

  &__mail-slogan {
    margin-right: 3px;
  }

  @media (max-width: $tablet-width) {
    &--account {
      .page-footer__container {
        padding-top: 40px;
      }
    }

    &__container {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  @media (max-width: $mobile-width) {
    &--account {
      .page-footer__container {
        padding-top: 49px;
      }
    }

    &__container {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 20px 24px;
      position: relative;
    }

    &__copyrights {
      position: absolute;
      left: 20px;
      bottom: 57px;
    }

    &__wrap {
      flex-direction: column;
      align-items: flex-start;
      order: -1;
    }

    &__contract-link {
      margin-left: 0;
    }

    &__phone-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 9px;
    }

    &__phone-link {
      color: $charcoal;
    }

    &__phone-slogan {
      margin-right: 3px;
    }

    &__mail-wrap {
      margin-bottom: 40px;
    }
  }
}
