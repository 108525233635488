.address {
  &__list {
    @include reset-list;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    margin-right: 20px;
    max-width: 540px;
    width: 48%;
    min-width: 470px;
  }

  &__details {
    margin-left: 31px;
    font-size: 13px;
    color: $charcoal;
  }

  &__label {
    font-size: 16px;
    cursor: pointer;
    padding-left: 31px;
    position: relative;
  }

  &__label::before {
    content: "";
    position: absolute;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 2px solid $pale-charcoal;
    top: 0;
    left: 0;
  }

  &__input:checked + &__label::before {
    border-color: $orange;
  }

  &__input:checked + &__label::after {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: $orange;
    left: 6px;
    top: 6px;
  }

  @media (max-width: $tablet-width) {
    &__list {
      flex-direction: column;
    }

    &__item {
      max-width: none;
      width: 100%;
      margin-right: 0;
      min-width: auto;
    }
  }

  @media (max-width: $mobile-width) {
    margin-bottom: 14px;
  }
}
