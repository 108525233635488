.sorting {
  padding: 0 10px 32px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__arrow-icon {
    display: none;
  }

  &__slogan {
    color: $charcoal;
    font-size: 16px;
    margin-right: 16px;
  }

  &__button-type {
    font-size: 16px;
  }

  &__wrap {
    display: flex;
    align-items: center;
  }

  &__checkbox-label {
    position: relative;
    font-size: 16px;
    cursor: pointer;
    padding-left: 29px;
  }

  &__checkbox-label::before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 18px;
    height: 18px;
    border: 1px solid $pale-charcoal;
    border-radius: 2px;
  }

  &__checkbox-input:checked + &__checkbox-label::before {
    background-color: $orange;
    border-color: $orange;
  }

  &__checkbox-input:checked + &__checkbox-label::after {
    position: absolute;
    left: 3px;
    top: 3px;
    content: "";
    background-repeat: no-repeat;
    background-size: 100%;
    width: 12px;
    height: 12px;
    background-image: url("../img/icons/icon-white-check.svg");
  }

  &__button {
    @include reset-button;

    color: $charcoal;

    &--size {
      margin-right: 23px;
    }

    &--active {
      color: $black;
      display: flex;
      align-items: center;

      .sorting__arrow-icon {
        display: block;
      }

      .sorting__button-type {
        margin-right: 11px;
      }
    }

    &--big-to-low {
      .sorting__arrow-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__buttons-wrap {
    display: flex;
    align-items: center;
  }

  @media (max-width: $tablet-width) {
    padding-right: 24px;
    padding-left: 24px;
  }

  @media (max-width: $mobile-width) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 5.3% 30px;

    &__wrap {
      margin-bottom: 24px;
      flex-wrap: wrap;
    }

    &__button {
      flex-shrink: 0;
    }

    &__checkbox-label {
      padding-left: 32px;
    }

    &__checkbox-label::before {
      left: 3px;
    }

    &__checkbox-input:checked + &__checkbox-label::after {
      left: 6px;
    }
  }
}
