.pop-up-push {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background: rgba(0, 0, 0, 0.4);
  transition: all .3s ease;

  &--hidden {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  // .pop-up-push__body
  &__body {
    position: relative;
    max-width: 600px;
    padding: 32px;
    border-radius: 4px;
    background: #fff;
  }
  // .pop-up-push__close
  &__close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    background: none;
    border: 0;
  }
  // .pop-up-push__content
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  // .pop-up-push__text
  &__text {
    text-align: center;
    color: #424242;
  }
  // .pop-up-push__submit
  &__submit {
    cursor: pointer;
    display: flex;
    text-align: center;
    padding: 10px 20px;
    margin: 24px auto 0;
    border-radius: 30px;
    background: #f57e00;
    color: #fff;
    border: 0;
    font-weight: 700;
  }
}
