.settings {
  background-color: $white;
  width: 100%;

  &--company-ip {
    .settings__company-person-wrap {
      display: block;
    }
  }

  &--company-ooo {
    .settings__company-ooo-wrap {
      display: block;
      padding-top: 31px;
    }

    .settings__passport-details {
      display: none;
    }
  }

  &--ip-no-requisites,
  &--ooo-no-requisites {
    .settings__no-requisites-note {
      display: block;
    }

    .settings__requisites-slogan {
      margin-bottom: 16px;
    }

    .settings__details-wrap {
      display: none;
    }

    .settings__add-requisites {
      display: inline-block;
    }

    .settings__edit-requisites {
      display: none;
    }

    .settings__company-person-container {
      margin-bottom: 0;
    }
  }

  &--on-moderate {
    .settings__moderation-message {
      display: block;

      padding-left: 14px;
      border-left: 2px solid $bright-red;
      color: $bright-red;
      font-size: 16px;
      font-style: italic;
    }

    .settings__title {
      margin-bottom: 16px;
    }
  }

  &__moderation-message {
    display: none;
  }

  &__company-person-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &__info-slogan {
    font-size: 15px;
    display: inline-block;
    vertical-align: top;
    width: 185px;
    margin-right: 32px;
    flex-shrink: 0;
  }

  &__info-item {
    display: flex;
    align-items: center;
  }

  &__info-item:not(:last-of-type) {
    margin-bottom: 16px;
  }

  &__add-requisites {
    @include reset-button;

    display: none;
    vertical-align: top;
    color: $white;
    text-align: center;
    width: 200px;
    font-size: 16px;
    line-height: 24px;
    background-color: $orange;
    transition: background-color 0.5s ease-out 0.5s;
    padding: 12px;
    border-radius: 30px;
  }

  &__add-requisites:hover,
  &__add-requisites:focus {
    background-color: $red;
  }

  &__title {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    color: $dark-black;
    margin: 0;
  }

  &__back-link {
    display: none;
  }

  &__title-wrap {
    margin: 0 0 32px;
  }

  &__name {
    font-size: 19px;
    margin-bottom: 24px;
  }

  &__slogan {
    font-size: 13px;
    line-height: 16px;
    color: $charcoal;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 8px;
  }

  &__number,
  &__mail {
    font-size: 16px;
    margin-right: 8px;
  }

  &__list {
    @include reset-list;

    display: flex;
  }

  &__edit-button,
  &__edit-requisites {
    @include reset-button;

    color: $orange;
    font-size: 16px;
    line-height: 24px;
    transition: color 0.5s ease-out 0.5s;
  }

  &__edit-password {
    font-size: 16px;
    line-height: 24px;
    color: $orange;
    transition: color 0.5s ease-out 0.5s;
  }

  &__edit-button:hover,
  &__edit-button:focus,
  &__edit-password:hover,
  &__edit-password:focus,
  &__edit-requisites:hover,
  &__edit-requisites:focus {
    color: $red;
  }

  &__wrap {
    padding: 32px 32px 31px;
    border-bottom: 1px solid $very-light-grey;
  }

  &__passport-slogan {
    font-size: 19px;
    margin-bottom: 16px;
  }

  &__passport-details {
    padding: 32px 32px 40px;
  }

  &__item {
    display: flex;
    flex-direction: column;

    &--phone {
      margin-right: 10.3%;
    }

    &--mail {
      margin-right: 14.5%;
    }
  }

  &__details {
    display: flex;
    align-items: center;
  }

  &__company-person-wrap,
  &__company-ooo-wrap {
    display: none;
    padding: 23px 32px 40px;
    border-top: 1px solid $very-light-grey;
  }

  &__requisites-slogan {
    font-size: 19px;
  }

  &__no-requisites-note {
    display: none;

    color: $bright-red;
    padding-left: 14px;
    margin-bottom: 17px;
    border-left: 2px solid $bright-red;
    font-size: 16px;
  }

  @media (max-width: $tablet-width) {
    &--on-moderate {
      .settings__back-link {
        order: 1;
      }

      .settings__title {
        order: 0;
        margin-bottom: 17px;
      }

      .settings__moderation-message {
        order: -1;
      }
    }

    &__back-link {
      display: flex;
      align-items: center;
      color: $orange;
      transition: color 0.5s ease-out 0.5s;
      margin-bottom: 32px;
    }

    &__back-link:hover,
    &__back-link:focus {
      color: $red;
    }

    &__back-link span {
      font-size: 19px;
      line-height: 24px;
    }

    &__title-wrap {
      display: flex;
      flex-direction: column-reverse;
    }

    &__back-icon {
      margin-right: 13px;
    }

    &__wrap {
      padding: 15px 24px 31px;
    }

    &__passport-details {
      padding: 32px 24px 41px;
    }

    &__item {
      &--mail {
        margin-right: 15%;
      }
    }

    &__company-person-wrap,
    &__company-ooo-wrap {
      padding-left: 23px;
      padding-right: 23px;
    }
  }

  @media (max-width: $mobile-width) {
    &__list {
      flex-direction: column;
      align-items: flex-start;
    }

    &__item {
      &--phone {
        margin-right: 0;
        margin-bottom: 16px;
      }

      &--mail {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    &__wrap {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 29px;
    }

    &__slogan {
      margin-bottom: 9px;
    }

    &__passport-details {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__company-person-wrap,
    &__company-ooo-wrap {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__info-slogan {
      width: 120px;
    }

    &__company-person-container {
      flex-direction: column;
      align-items: flex-start;
    }

    &__requisites-slogan {
      margin-bottom: 14px;
    }
  }
}
