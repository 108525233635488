.sale {
  background-color: $pale-grey;
  border-left: 1px solid $orange;
  padding: 18px 19px;
  display: flex;
  align-items: center;

  &__list {
    @include reset-list;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    margin: 5px 0;
  }

  &__item:not(:last-child) {
    margin-right: 24px;
  }

  &__slogan {
    font-size: 19px;
    line-height: 32px;
    margin-right: 12%;
    flex-shrink: 0;
  }

  &__link {
    padding: 4px 16px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    font-size: 16px;
    border-radius: 25px;
    border: 1px solid $orange;
  }

  &__percent {
    font-weight: 700;
    color: $orange;
  }

  &__days {
    color: $black;
  }

  @media (max-width: $tablet-width) {
    flex-direction: column;
    align-items: flex-start;
    padding: 23px 19px 11px;

    &__slogan {
      margin-right: 0;
      margin-bottom: 8px;
    }

    &__item {
      margin-top: 0;
      margin-bottom: 12px;
    }

    &__item:not(:last-child) {
      margin-right: 10px;
    }
  }

  @media (max-width: $mobile-width) {
    align-items: center;
    padding: 24px 20px;

    &__list {
      flex-direction: column;
    }

    &__item {
      margin-bottom: 0;
    }

    &__item:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }

    &__slogan {
      text-align: center;
      line-height: 28px;
      margin-bottom: 10px;
    }
  }
}
