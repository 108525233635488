.catalog {
  &__closed-availability,
  &__free-availability,
  &__open-to-rent,
  &__closed-for-renting {
    display: none;
  }

  &__price {
    font-weight: 700;
    font-size: 16px;
  }

  &__list {
    @include reset-list;
    padding: 0 10px 230px;
  }

  &__properties {
    font-size: 16px;
  }

  &__availability {
    width: 118px;
  }

  &__item {
    padding: 32px 0;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--available {
      .catalog__free-availability {
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        color: $green;
      }

      .catalog__open-to-rent {
        @include reset-button;

        display: inline-block;
        vertical-align: top;
        text-align: center;
        width: 200px;
        color: $white;
        border-radius: 30px;
        background-color: $orange;
        font-size: 16px;
        padding: 15px;
        transition: background-color 0.5s ease-out 0.5s;
      }

      .catalog__open-to-rent:hover,
      .catalog__open-to-rent:focus {
        background-color: $dark-orange;
      }
    }

    &--employed {
      .catalog__closed-for-renting {
        font-size: 16px;
        width: 200px;
        border-radius: 30px;
        display: inline-block;
        text-align: center;
        vertical-align: top;
        padding: 12px;
        background-color: #f0f0f0;
        color: $black;
        transition: background-color 0.5s ease-out 0.5s;
      }

      .catalog__closed-for-renting:hover,
      .catalog__closed-for-renting:focus {
        background-color: $dark-pale-grey;
      }

      .catalog__closed-availability {
        display: inline;
        color: $bright-red;
        font-size: 16px;
      }
    }
  }

  &__image {
    width: 108px;
    height: 108px;
    max-width: 108px;
    max-height: 108px;
    flex: 1;
    margin-right: 10px;
    background: #F0F0EE url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 22.5C20.4853 22.5 22.5 20.4853 22.5 18C22.5 15.5147 20.4853 13.5 18 13.5C15.5147 13.5 13.5 15.5147 13.5 18C13.5 20.4853 15.5147 22.5 18 22.5Z' fill='white'/%3E%3Cpath d='M30 6H25.245L23.385 3.975C23.1055 3.66809 22.765 3.42286 22.3853 3.25498C22.0056 3.0871 21.5951 3.00026 21.18 3H14.82C13.98 3 13.17 3.36 12.6 3.975L10.755 6H6C4.35 6 3 7.35 3 9V27C3 28.65 4.35 30 6 30H30C31.65 30 33 28.65 33 27V9C33 7.35 31.65 6 30 6ZM18 25.5C13.86 25.5 10.5 22.14 10.5 18C10.5 13.86 13.86 10.5 18 10.5C22.14 10.5 25.5 13.86 25.5 18C25.5 22.14 22.14 25.5 18 25.5Z' fill='white'/%3E%3C/svg%3E%0A") center/50% no-repeat;
    object-fit: cover;
  }

  &__name {
    margin: 0 0 8px;
    font-size: 19px;
    font-weight: 400;
  }

  &__wrap {
    display: flex;
    max-width: 350px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-right: 5px;
  }

  &__item-container {
    display: flex;
    align-items: center;
    max-width: 690px;
    width: 100%;
    justify-content: space-between;
  }

  &__item-container--img {
    flex-direction: unset;
    margin-bottom: 1rem;
  }

  &__sizes-wrap,
  &__height-wrap,
  &__scope-wrap {
    display: flex;
    align-items: center;
  }

  &__details {
    position: relative;
    max-width: 200px;
    width: 200px;
    word-break: break-all;
  }

  &__nothing-was-found {
    padding: 32px 10px 144px;
  }

  &__nothing-slogan {
    font-size: 19px;
    line-height: 24px;
    color: $dark-black;
    font-weight: 400;
    margin: 0 0 8px;
  }

  &__nothing-actions-list {
    @include reset-list;
  }

  &__nothing-item {
    padding-left: 16px;
    font-size: 16px;
    line-height: 24px;
    color: $charcoal;
    position: relative;
  }

  &__nothing-item::before {
    position: absolute;
    content: "–";
    font-size: 16px;
    line-height: 24px;
    color: $charcoal;
    left: 0;
  }

  &__phone-link {
    color: $charcoal;
  }

  &__phone-link:hover,
  &__phone-link:focus {
    text-decoration: underline;
  }


  &__nothing-item:not(:last-child) {
    margin-bottom: 4px;
  }

  @media (max-width: $tablet-width) {
    &__wrap {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__item-container {
      max-width: 404px;
    }

    &__item-container--img {
      flex-direction: unset;
      margin-bottom: 1rem;
    }

    &__price {
      margin-bottom: 8px;
    }

    &__list {
      padding: 0 24px 180px;
    }

    &__nothing-was-found {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  @media (max-width: $mobile-width) {
    &__list {
      padding: 16px 5.3% 59px;
      background-color: $very-pale-grey;
    }

    &__item {
      flex-direction: column;
      align-items: flex-start;
      background-color: $white;
      padding: 24px 21px;
      border-bottom: 0;
      box-shadow: 0 1px 5px rgba(189, 189, 189, 0.35);

      &--available {
        .catalog__open-to-rent {
          width: 100%;
        }
      }

      &--employed {
        .catalog__closed-for-renting {
          width: 100%;
        }
      }
    }

    &__item:not(:last-child) {
      margin-bottom: 8px;
    }

    &__item-container {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      max-width: none;
    }

    &__item-container--img {
      flex-direction: unset;
      margin-bottom: 1rem;
    }

    &__wrap {
      margin-right: 0;
      max-width: none;
      width: 100%;
      margin-bottom: 16px;
    }

    &__ordering {
      width: 100%;
    }

    &__properties {
      margin-bottom: 16px;
    }

    &__price {
      font-size: 19px;
    }

    &__nothing-was-found {
      padding-left: 5.3%;
      padding-right: 5.3%;
    }
  }
}
