.list-header {
  box-shadow: 0 1px 5px rgba(189, 189, 189, 0.35);
  z-index: 5;

  &__user-profile-link {
    display: none;
  }

  &__enter-slogan {
    font-size: 16px;
    text-decoration: underline;
    margin-left: 11px;
  }

  &__register-link {
    font-size: 16px;
    text-decoration: underline;
    color: $black;
    margin-left: 23px;
  }

  &__authorization {
    display: flex;
    align-items: center;
  }

  &__enter-link {
    color: $black;
    display: flex;
    align-items: center;
    position: relative;
  }

  &__enter-link::before {
    position: absolute;
    content: "/";
    color: $black;
    font-size: 16px;
    top: 0;
    right: -15px;
  }

  &--user-logged-in {
    .list-header__user-profile-link {
      color: $black;
      font-size: 16px;
      display: flex;
      align-items: center;
    }

    .list-header__authorization {
      display: none;
    }
  }

  &__container {
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 10px;
  }

  &__wrap {
    display: flex;
    align-items: center;
  }

  &__phone {
    font-size: 16px;
    color: $charcoal;
    font-style: italic;
  }

  &__telegram {
    font-size: 16px;
    color: $charcoal;
    font-style: italic;
    padding: 12px;
  }

  &__profile-icon {
    color: $black;
    margin-right: 13px;
  }

  &__logo-image {
    display: block;
    //width: 215px;
    height: 80px;
  }

  &__logo-link {
    margin-right: 48px;
  }

  &__mobile-profile-icon {
    display: none;
  }

  &__container--mobile {
    display: none;
  }

  @media (max-width: $tablet-width) {
    &__container {
      padding-left: 24px;
      padding-right: 24px;
    }

    &__logo-image {
      //width: 172px;
      height: 64px;
    }
  }

  @media (max-width: $mobile-width) {
    &__profile-icon,
    &__profile-slogan,
    &__telegram,
    &__phone {
      display: none;
    }

    &__mobile-profile-icon {
      display: block;
      color: $black;
    }

    &__container--mobile {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
    }

    &__container--mobile &__telegram,
    &__container--mobile &__phone {
      display: block;
    }
  }

  @media (max-width: $mobile-width) {
    &__container {
      padding-left: 5.3%;
      padding-right: 5.3%;
    }

    &__enter-slogan,
    &__enter-link::before,
    &__register-link {
      display: none;
    }

    &__enter-icon {
      width: 22px;
      height: 26px;
    }
  }
}
