.payment {
  background-color: $white;
  padding: 32px 32px 40px;

  &__back-link {
    display: none;
  }

  &__title {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;

    margin-bottom: 32px;
  }

  &__nav-link {
    font-size: 19px;
    color: $pale-charcoal;


    &--active {
      color: $black;
    }

    &--charges {
      margin-right: 32px;
    }
  }

  &__links-wrap {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid $very-light-grey;
    margin-bottom: 32px;
  }

  &__address-select,
  &__number-item-select,
  &__year-select {
    border: 1px solid $light-charcoal;
    box-sizing: border-box;
    box-shadow: inset 0 28px 20px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    background-color: $white;
    font-size: 16px;
    line-height: 24px;
    padding: 7px 20px 6px 11px;
    color: $black;
    width: 100%;
    background-image: url("../img/icons/icon-double-select-arrows.svg");
    background-repeat: no-repeat;
    background-position: top 9px right 7px;
    background-size: 20px 20px;
    display: block;
    appearance: none;
    outline: 0;
    cursor: pointer;
  }

  &__address-select,
  &__number-item-select {
    max-width: 280px;
    margin-right: 16px;
  }

  &__year-select {
    max-width: 128px;
  }

  &__select-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
  }

  &__head-number,
  &__head-operation,
  &__head-period,
  &__head-sum,
  &__head-date {
    font-weight: 400;
    color: $charcoal;
    font-size: 14px;
    line-height: 20px;
    padding: 0;
  }

  &__table {
    width: 100%;
  }

  &__head-number {
    width: 99px;
    margin-right: 15px;
  }

  &__tbody {
    width: 100%;
  }

  &__head {
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid $very-light-grey;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__list {
    @include reset-list;
  }

  &__item {
    padding: 16px 0 15px;
    border-bottom: 1px solid $very-light-grey;
    font-size: 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item-wrap {
    width: 100%;
    max-width: 510px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 30px;
  }

  &__number {
    margin-right: 10%;
  }

  &__operation {
    width: 170px;
    margin-right: 5px;
  }

  &__pay-details {
    width: 41%;
    max-width: 184px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__head-operation {
    width: 167px;
    margin-right: 5%;
  }

  &__head-period {
    width: 180px;
    margin-right: 6.5%;
  }

  &__head-sum {
    margin-right: 4%;
  }

  &__head-date {
    flex-shrink: 0;
  }

  &__sum {
    &--plus {
      color: $green;
    }
  }

  &__period,
  &__head-number {
    flex-shrink: 0;
  }

  &__acts-form {
    .payment__address-select,
    .payment__number-item-select {
      margin-bottom: 16px;
      width: 362px;
      max-width: none;
    }
  }

  &__date-input,
  &__modal-input {
    border: 1px solid $light-charcoal;
    border-radius: 4px;
    background-color: $white;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    outline: none;
  }

  &__date-input {
    width: 148px;
    box-shadow: inset 0 28px 20px rgba(0, 0, 0, 0.06);
    padding: 8px 30px 7px 12px;
  }

  &__date-input::placeholder,
  &__modal-input::placeholder {
    color: $black;
  }

  &__modal-input {
    padding: 12px 16px;
    width: 100%;
  }

  &__calendar-button {
    @include reset-button;

    width: 16px;
    height: 18px;
    position: absolute;
    right: 45px;
    top: 12px;
  }

  &__date-item {
    position: relative;
    width: 181px;
    margin-left: 33px;

    &--start {
      margin-bottom: 16px;
    }

    &--start::before,
    &--end::before {
      position: absolute;
      font-size: 16px;
      line-height: 24px;
      color: $black;
      top: 50%;
      transform: translateY(-50%);
      left: -32px;
    }

    &--start::before {
      content: "c";
    }

    &--end::before {
      content: "по";
    }
  }

  &__dates-period-wrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }

  &__acts-list {
    @include reset-list;

    margin-bottom: 32px;
  }

  &__radio-label {
    position: relative;
    font-size: 15px;
    cursor: pointer;
    padding-left: 30px;
  }

  &__radio-label::before {
    position: absolute;
    content: "";
    border-radius: 50%;
    width: 21px;
    height: 21px;
    border: 1.5px solid $pale-charcoal;
    left: 2px;
    top: -2px;
  }

  &__radio-input:checked + .payment__radio-label::before {
    border-color: $orange;
  }

  &__radio-input:checked + .payment__radio-label::after {
    position: absolute;
    content: "";
    border-radius: 50%;
    width: 9px;
    height: 9px;
    background-color: $orange;
    top: 4px;
    left: 8px;
  }

  &__download,
  &__send-to-email,
  &__submit-sending {
    @include reset-button;

    display: inline-block;
    vertical-align: top;
    text-align: center;
    border-radius: 30px;
    font-size: 16px;
    line-height: 24px;
    padding: 12px;
    transition: background-color 0.5s ease-out 0.5s;
  }

  &__download {
    width: 102px;
    color: $white;
    background-color: $orange;
    margin-right: 16px;
  }

  &__submit-sending {
    width: 100%;
    background-color: $orange;
    color: $white;
  }

  &__send-to-email {
    color: $black;
    background-color: $pale-grey;
    width: 191px;
  }

  &__download:hover,
  &__download:focus,
  &__submit-sending:hover,
  &__submit-sending:focus {
    background-color: $red;
  }

  &__download:disabled,
  &__submit-sending:disabled {
    color: $half-white;
    cursor: default;
  }

  &__send-to-email:disabled {
    cursor: default;
    color: $half-black;
  }

  &__download:disabled:hover,
  &__download:disabled:focus,
  &__submit-sending:disabled:hover,
  &__submit-sending:disabled:focus {
    background-color: $orange;
  }

  &__send-to-email:hover,
  &__send-to-email:focus {
    background-color: $dark-pale-grey;
  }

  &__send-to-email:disabled:hover,
  &__send-to-email:disabled:focus {
    background-color: $half-white;
  }

  &__buttons-wrap {
    display: flex;
    align-items: center;
  }

  &__acts-item {
    &--work-done {
      margin-bottom: 16px;
    }
  }

  &__modal {
    position: fixed;
    background-color: $white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 1px 5px rgba(189, 189, 189, 0.45);
    border-radius: 4px;
    width: 416px;
    padding: 48px 48px 40px;
    z-index: 5;

    &--hidden {
      display: none;
    }
  }

  &__close-button {
    @include reset-button;

    position: absolute;
    width: 14px;
    height: 14px;
    top: 21px;
    right: 21px;
  }

  &__close-button::before,
  &__close-button::after {
    position: absolute;
    content: "";
    width: 18px;
    height: 1.5px;
    background-color: $charcoal;
    top: 5px;
    left: -1px;
  }

  &__close-button::before {
    transform: rotate(-45deg);
  }

  &__close-button::after {
    transform: rotate(45deg);
  }

  &__modal-title {
    text-align: center;
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    color: $dark-black;
    margin-bottom: 16px;
  }

  &__modal-details {
    text-align: center;
    font-size: 16px;
    margin-bottom: 32px;
  }

  &__error {
    color: $orange;
    font-size: 12px;
    display: none;
    position: absolute;
    right: 2px;
    bottom: -20px;
  }

  &__modal-item {
    position: relative;
    margin-bottom: 29px;

    &--error {
      .payment__modal-input {
        border-color: $orange;
      }

      .payment__error {
        display: block;
      }
    }
  }

  @media (max-width: $tablet-width) {
    padding: 16px 23px 40px;

    &__back-link {
      display: flex;
      align-items: center;

      margin-bottom: 32px;
      color: $orange;
      transition: color 0.5s ease-out 0.5s;
      width: 80px;
    }

    &__back-link:hover,
    &__back-link:focus {
      color: $red;
    }

    &__back-link span {
      font-size: 19px;
    }

    &__back-icon {
      margin-right: 13px;
    }

    &__head-number {
      margin-right: 18px;
    }

    &__head-operation {
      margin-right: 5.3%;
    }

    &__pay-details {
      width: 176px;
    }

    &__select-wrap {
      justify-content: space-between;
    }

    &__address-select,
    &__number-item-select {
      max-width: none;
    }

    &__radio-label {
      padding-left: 34px;
    }

    &__radio-input:checked + .payment__radio-label::after {
      left: 8px;
      top: 4px;
    }
  }

  @media (max-width: $mobile-width) {
    padding-left: 20px;
    padding-right: 20px;

    &__head {
      display: none;
    }

    &__select-wrap {
      flex-direction: column;
      align-items: flex-start;
      justify-content: stretch;
      margin-bottom: 31px;
    }

    &__address-select,
    &__number-item-select {
      margin-right: 0;
      margin-bottom: 17px;
    }

    &__year-select {
      max-width: none;
    }

    &__item-wrap {
      flex-direction: column;
      align-items: flex-start;
    }

    &__pay-details {
      flex-direction: column-reverse;
      align-items: flex-end;
    }

    &__number {
      margin-right: 0;
      margin-bottom: 4px;
    }

    &__operation {
      margin-right: 0;
      margin-bottom: 4px;
    }

    &__item {
      align-items: stretch;
    }

    &__list {
      border-top: 1px solid $very-light-grey;
    }

    &__acts-form {
      .payment__address-select,
      .payment__number-item-select {
        width: 100%;
      }
    }

    &__buttons-wrap {
      flex-direction: column;
      align-items: flex-start;
    }

    &__download {
      width: 100%;
      margin-bottom: 16px;
    }

    &__send-to-email {
      width: 100%;
    }

    &__radio-label {
      padding-left: 32px;
    }

    &__acts-list {
      margin-bottom: 31px;
    }

    &__modal {
      width: 100%;
      max-width: 320px;
      padding-left: 20px;
      padding-right: 20px;
    }

    &__close-button {
      right: 26px;
    }
  }
}
