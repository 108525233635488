.page-header {
  &__logo-link {
    display: block;
    width: 172px;
    height: 64px;
    margin: 16px auto 17px;
  }

  &__back-link {
    position: absolute;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 17px;
    right: 18px;
    color: $charcoal;
  }

  &__logo-image {
    display: block;
  }

  &--sms-code,
  &--new-password {
    .page-header__logo-link {
      display: none;
    }
  }

  @media (max-width: $mobile-width) {
    padding: 16px 0 17px;

    &--sms-code,
    &--new-password {
      .page-header__logo-link {
        display: block;
      }
    }

    &__logo-link {
      margin: 0 auto;
    }

    &__back-link {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background-color: $pale-grey;
      top: 16px;
      right: 16px;
      color: $black;
    }
  }
}
