.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  box-shadow: 0 1px 5px rgba(189, 189, 189, 0.45);
  border-radius: 4px;
  z-index: 5;

  &--add-requisites {
    width: 416px;
    padding: 48px 48px 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--moderation {
    width: 576px;
    padding: 48px;
  }

  &--hidden {
    display: none;
  }

  &--error {
    padding: 48px 75px;
    z-index: 55;
  }

  &__close-button {
    @include reset-button;

    width: 15px;
    height: 15px;
    position: absolute;
    top: 21px;
    right: 21px;
  }

  &__close-button::before,
  &__close-button::after {
    position: absolute;
    content: "";
    top: 5;
    left: -2px;
    width: 18px;
    height: 2px;
    background-color: $charcoal;
  }


  &__close-button::before {
    transform: rotate(-45deg);
  }

  &__close-button::after {
    transform: rotate(45deg);
  }

  &__slogan {
    font-size: 16px;
    text-align: center;
    margin-bottom: 16px;
  }

  &__add-requisites {
    color: $white;
    background-color: $orange;
    transition: background-color 0.5s ease-out 0.5s;
    border-radius: 30px;
    width: 200px;
    padding: 12px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
  }

  &__add-requisites:hover,
  &__add-requisites:focus {
    background-color: $dark-orange;
  }

  &__moderation-info {
    font-size: 16px;
    text-align: center;
  }

  &__error-text {
    font-size: 16px;
    text-align: center;
  }

  @media (max-width: $mobile-width) {
    max-width: 320px;
    width: 100%;

    &--moderation,
    &--add-requisites {
      padding: 48px 20px 40px;
    }

    &--error {
      padding: 48px 20px 40px;
    }
  }
}
