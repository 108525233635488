.registration {
  padding: 0 48px 64px;

  &__slogan {
    text-align: center;
    font-size: 30px;
    line-height: 36px;
    color: $dark-black;
    margin-bottom: 16px;
    font-weight: 300;
  }

  &__text {
    text-align: center;
    color: $charcoal;
    margin-bottom: 32px;
    font-size: 16px;
  }

  &__progress-line {
    @include reset-list;

    display: flex;
    width: 250px;
    justify-content: space-between;
    margin: 0 auto 32px;
  }

  &__status-type {
    font-size: 19px;
    margin-bottom: 32px;
    text-align: center;
  }

  &__progress-item {
    display: block;
    width: 50px;
    height: 3px;
    border-radius: 3px;
    background-color: $light-grey;

    &--active {
      background-color: $orange;
    }
  }

  &__user-types-links {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    font-size: 16px;
  }

  &__user-type-link {
    border-radius: 50px;
    background-color: $pale-grey;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    padding: 12px;
    color: $black;
    font-size: 16px;
    transition: all 0.5s ease-out 0.5s;

    &--person {
      margin-bottom: 16px;
    }
  }

  &__user-type-link:hover,
  &__user-type-link:focus {
    background-color: $dark-pale-grey;
  }

  &__already-registred {
    text-align: center;
    font-size: 16px;
  }

  &__enter-link {
    color: $orange;
    transition: all 0.5s ease-out 0.5s;
  }

  &__profile-creation {
    font-size: 19px;
    text-align: center;
    margin-bottom: 29px;
  }

  &__to-back-page {
    font-size: 16px;
    color: $charcoal;
    margin: 0 auto 32px;
    width: 50px;
    display: block;
    transition: all 0.5s ease-out 0.5s;
  }

  &__details-slogan {
    text-align: center;
    font-size: 19px;
    margin-bottom: 28px;
  }

  &__contact-person-slogan {
    text-align: center;
    font-size: 19px;
    margin-bottom: 28px;
  }

  &__confirmation {
    font-size: 16px;
    margin-bottom: 32px;
    text-align: center;
  }

  &__rules {
    color: $orange;
    transition: all 0.5s ease-out 0.5s;
  }

  &__enter-link:hover,
  &__enter-link:focus,
  &__rules:hover,
  &__rules:focus,
  &__to-back-page:hover,
  &__to-back-page:focus {
    color: $red;
  }

  &__form {
    position: relative;
  }

  &__big-error {
    position: absolute;
    color: $orange;
    z-index: 2;
    top: -29px;
    left: 1px;

    &--hidden {
      display: none;
    }
  }

  @media (max-width: $mobile-width) {
    padding: 0 20px 156px;

    &__status-type {
      margin-bottom: 28px;
    }
  }
}
