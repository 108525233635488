.note {
  margin: 0 0 4px 4px;
  // position: relative;
  cursor: pointer;

  &__text {
    display: none;
    line-height: 20px;
    width: 288px;
    padding: 16px;
    background-color: $white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.37);
    position: absolute;
    z-index: 3;
    top: 20px;
    right: -250px;
  }

  &__question-wrap {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid $orange;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    display: none;
  }
}

.note__question-wrap:hover + .note__text {
  display: block;
}


@media (max-width: $tablet-width) {
  .note {
    cursor: default;
  }

  .note__text {
    display: block;
    width: 255px;
    right: auto;
    left: 0;

    &--hidden {
      display: none;
    }
  }

  .note__question-wrap {
    display: none;
  }

  .note__button {
    @include reset-button;

    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid $orange;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
