.person-registering {
  &__input {
    width: 100%;
    outline: none;
    background-color: $white;
    border: 1px solid $light-charcoal;
    border-radius: 4px;
    padding: 14px 16px;
    font-size: 16px;
    color: $black;
  }

  &__input:focus {
    border-color: $black;
  }

  &__input::placeholder {
    color: $charcoal;
  }

  &__upload-details {
    line-height: 20px;
    color: $charcoal;
    padding-left: 3px;
  }

  &__upload-slogan {
    color: $orange;
    font-size: 16px;
    transition: all 0.5s ease-out 0.5s;
  }

  &__upload-label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__clip-icon {
    margin-left: 3px;
    margin-right: 9px;
  }

  &__submit {
    @include reset-button;

    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 100%;
    background-color: $orange;
    padding: 15px;
    font-size: 16px;
    color: $white;
    margin-bottom: 32px;
    border-radius: 50px;
    transition: all 0.5s ease-out 0.5s;

    &--company {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }

  &__submit:hover,
  &__submit:focus {
    background-color: $red;
  }

  &__submit:disabled {
    color: $half-white;
    cursor: default;
  }

  &__submit:disabled:hover,
  &__submit:disabled:focus {
    background-color: $orange;
  }

  &__error {
    display: none;
  }

  &__item {
    margin-bottom: 16px;
    position: relative;

    &--middle-name {
      margin-bottom: 31px;
    }

    &--error {
      .person-registering__input {
        border-color: $orange;
      }

      .person-registering__input:focus {
        border-color: $orange;
      }

      .person-registering__error {
        display: inline;
        color: $orange;
        font-size: 12px;
        position: absolute;
        right: 0;
        bottom: -19px;
      }
    }
  }

  &__upload-input {
    overflow: hidden;
    outline: none;
    opacity: 0;
    position: absolute;
    cursor: pointer;
    padding-left: 238px;
  }

  &__upload-input:hover + .person-registering__upload-label .person-registering__upload-slogan {
    color: $red;
  }

  &__upload-item {
    margin-bottom: 8px;
  }

  &__upload-wrap {
    padding-bottom: 3px;
    margin-bottom: 29px;
    position: relative;

    &--hidden {
      display: none;
    }

    &--company {
      margin-top: 16px;
      margin-bottom: 0;
    }

    &--error {
      border: 1px solid $orange;
      border-radius: 3px;

      .person-registering__error {
        display: inline;
        color: $orange;
        font-size: 12px;
        position: absolute;
        right: 0;
        bottom: -22px;
      }
    }
  }

  &__uploded-images-list {
    @include reset-list;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__preview-item {
    margin-right: 16px;
    margin-top: 16px;
    position: relative;
    border: 1px dashed $orange;
    border-radius: 3px;
    width: 100px;
    height: 100px;
  }

  &__preview-item::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32));
  }

  &__preview-image {
    width: 100px;
    height: 100px;
  }

  &__preview-close-button {
    @include reset-button;

    position: absolute;
    top: 9px;
    right: 9px;
    width: 15px;
    height: 15px;
  }

  &__preview-close-button::before,
  &__preview-close-button::after {
    position: absolute;
    content: "";
    width: 15px;
    height: 3px;
    background-color: $white;
    left: 1px;
    top: 5px;
  }

  &__preview-close-button::before {
    transform: rotate(-45deg);
  }

  &__preview-close-button::after {
    transform: rotate(45deg);
  }

  &__checkbox-label {
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    font-size: 16px;
  }

  &__checkbox-label::before {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    border: 2px solid $pale-charcoal;
    border-radius: 2px;
    top: 1px;
    left: 3px;
    background-color: $white;
  }

  &__checkbox-input:checked + &__checkbox-label::before {
    background-color: $orange;
    border-color: $orange;
  }

  &__offer-agreement {
    .agreement-link {
      color: $orange;

      &:hover {
        color: $red;
      }
    }
    .agreement-text {
      color: #999;
    }
  }

  &__personal-data {
    .personal-data-link {
      color: $orange;

      &:hover {
        color: $red;
      }
    }
  }

  &__checkbox-input:checked + &__checkbox-label::after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-image: url("../img/icons/icon-white-check.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    top: 5px;
    left: 7px;
  }

  @media (max-width: $tablet-width) {
    &__input {
      box-shadow: inset 0 4px 4px rgba(189, 189, 189, 0.25);
    }
  }
}
