.account {
  &--no-rent {
    .account__content {
      display: none;
    }

    .account__container {
      align-items: stretch;
    }

    .account__no-rent-yet {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $white;
      width: 100%;
      box-shadow: 0 1px 5px rgba(189, 189, 189, 0.35);
    }

    .my-boxes__list,
    .my-boxes__rent-link {
      display: none;
    }

    .my-boxes__rent-box-link {
      display: inline-block;
      vertical-align: top;
    }
  }

  &__overdue {
    display: none;
    padding: 32px 32px 40px;
  }

  &__no-rent-yet {
    display: none;
  }

  &__no-rent-slogan {
    font-size: 16px;
    color: $charcoal;
    margin-top: 68px;
    margin-bottom: 16px;
  }

  &__no-rent-notice {
    margin-top: 16px;
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
  }

  &__boxes-link {
    width: 229px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    background-color: $orange;
    color: $white;
    border-radius: 30px;
    padding: 12px;
    transition: background-color 0.5s ease-out 0.5s;
  }

  &__boxes-link:hover,
  &__boxes-link:focus {
    background-color: $red;
  }

  &__container {
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
  }

  &__content {
    width: 100%;
  }

  &__button {
    @include reset-button;

    font-size: 19px;
    color: $orange;

    &--active {
      color: $black;
    }
  }

  &__name {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    color: $dark-black;
    margin-bottom: 8px;
  }

  &__address {
    font-size: 16px;
    color: $charcoal;
  }

  &__item-wrap {
    padding: 32px;
    border-bottom: 1px solid $very-light-grey;
  }

  &__buttons-wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 32px 18px;
  }

  &__button:not(:last-of-type) {
    margin-right: 32px;
  }

  &__item {
    margin-bottom: 16px;
    background-color: $white;
    box-shadow: 0 1px 5px rgba(189, 189, 189, 0.35);
  }

  &__enter {
    background-color: $white;
    box-shadow: 0 1px 5px rgba(189, 189, 189, 0.35);
    margin-bottom: 16px;

    &--hidden {
      display: none;
    }

    &--overdue {
      .account__wrap {
        display: none;
      }

      .account__overdue {
        display: block;
      }

      .account__overdue-message {
        margin-bottom: 32px;
      }
    }

    &--overdue-paid {
      .account__overdue {
        display: block;
        border-bottom: 1px solid $very-light-grey;
      }

      .account__overdue-buttons-wrap {
        display: none;
      }
    }
  }

  .account__overdue-message {
    display: block;
    color: $bright-red;
    font-size: 16px;
    font-style: italic;
    padding-left: 14px;
    border-left: 2px solid $bright-red;
  }

  &__prolong-link {
    width: 171px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    background-color: $orange;
    color: $white;
    border-radius: 30px;
    padding: 12px;
    font-size: 16px;
    line-height: 24px;
    transition: background-color 0.5s ease-out 0.5s;
    margin-right: 16px;
  }

  &__prolong-link:hover,
  &__prolong-link:focus {
    background-color: $red;
  }

  &__pay-and-end-rent {
    @include reset-button;

    width: 264px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    background-color: $pale-grey;
    transition: background-color 0.5s ease-out 0.5s;
    padding: 12px;
    border-radius: 30px;
  }

  &__pay-and-end-rent:hover,
  &__pay-and-end-rent:focus {
    background-color: $dark-pale-grey;
  }

  &__overdue-buttons-wrap {
    display: flex;
    align-items: center;
  }

  &__title-main-enter {
    font-size: 16px;
  }

  &__lock-wrap {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background-color: $pale-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    flex-shrink: 0;
  }

  &__label-wrap {
    display: flex;
    align-items: center;
  }

  &__wrap {
    padding: 16px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--main-enter {
      border-bottom: 1px solid $very-light-grey;
    }

    &--opened {
      .account__lock-icon {
        display: none;
      }

      .account__opened-lock-icon {
        display: block;
      }
    }
  }

  &__opened-lock-icon {
    display: none;
  }

  &__temporarily-open,
  &__main-open,
  &__rollet-open {
    @include reset-button;

    color: $orange;
    font-size: 16px;
    transition: color 0.5s ease-out 0.5s;
  }

  &__temporarily-open:hover,
  &__temporarily-open:focus,
  &__main-open:hover,
  &__main-open:focus,
  &__rollet-open:hover,
  &__rollet-open:focus {
    color: $red;
  }

  &__temporarily-open {
    margin-left: 32px;
  }

  &__main-enter-buttons {
    display: flex;
    align-items: center;
  }

  &__back-link,
  &__mobile-link,
  &__mobile-link--span {
    display: none;
  }

  &__in-working {
    &--hidden {
      display: none;
    }

    background-color: $white;
    box-shadow: 0 1px 5px rgba(189, 189, 189, 0.35);
    padding: 16px 32px;
    margin-bottom: 16px;
    width: 100%;
  }

  &__in-work-slogan {
    font-size: 19px;
    margin-bottom: 6px;
  }

  &__soon-slogan,
  &__user-slogan {
    font-size: 16px;
    padding-left: 5px;
    color: $charcoal;
  }

  &__soon-slogan {
    margin-bottom: 6px;
  }

  @media (max-width: $tablet-width) {
    &--no-rent {
      .account__no-rent-yet {
        padding-bottom: 150px;
      }
    }

    &__container {
      padding-left: 0;
      padding-right: 0;
      margin-top: 16px;
    }

    &__back-link {
      display: flex;
      align-items: center;
      color: $orange;
      font-size: 19px;
      margin-bottom: 31px;
    }

    &__back-icon {
      margin-right: 13px;
    }

    &__item-wrap {
      padding: 18px 24px 30px;
    }

    &__buttons-wrap {
      padding: 17px 24px 18px;
    }

    &__wrap {
      padding: 16px 23px 15px;
    }

    &__overdue {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  @media (max-width: $mobile-width) {
    &__main-enter-buttons,
    &__rollet-open,
    &__dekstop-link {
      display: none;
    }

    &__button {
      flex-shrink: 0;
    }

    &__mobile-link {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $charcoal;
    }

    &__mobile-link--span {
      display: block;
      margin-left: 0.6rem;
      color: black;
      width: 100%;
      height: 100%;
    }

    &__item-wrap {
      padding: 16px 20px 32px;
    }

    &__wrap {
      padding: 18px 20px 13px;
    }

    &__buttons-wrap {
      flex-wrap: nowrap;
    }
  }

  @media (max-width: 515px) {
    &__buttons-wrap {
      overflow-x: auto;
      padding: 17px 0;
    }

    &__buttons-big-wrap {
      padding: 0 21px;
    }

    .account__buttons-wrap::-webkit-scrollbar {
      width: 1px;
      background-color: transparent;
    }

    .account__buttons-wrap:hover::-webkit-scrollbar {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &__overdue {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__enter {
      &--overdue {
        .account__overdue-buttons-wrap {
          flex-direction: column;
          align-items: flex-start;
        }

        .account__prolong-link {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
    }
  }
}
