html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", "Arial", sans-serif;
  color: $black;
  background-color: rgba(0, 0, 0, 0.35);
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
