.blackout {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-color: transparent;

  &--dark {
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 3;
  }

  &--hidden {
    display: none;
  }
}

.blackout--modal {
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #00000036;
  position: fixed;
  top: 0;
  display: flex;
  align-content: center;
  overflow: hidden;

  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 16px;
  }

  p {
    text-align: justify;
  }
}
