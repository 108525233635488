.notification {

  &__content {
    border: 1px solid #f57e00;
    border-left: 0;
    border-right: 0;
    min-height: 50px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;

    a {
      color: #f57e00;
      font-style: italic;
    }
  }
}
