.trust-persons {
  padding: 32px 32px 40px;
  background-color: $white;
  box-shadow: 0 1px 5px rgba(189, 189, 189, 0.35);

  &--hidden {
    display: none;
  }

  &__slogan {
    font-size: 16px;
    color: $charcoal;
    margin-bottom: 32px;
  }

  &__button,
  &__submit-button {
    @include reset-button;

    border-radius: 30px;
    display: inline-block;
    vertical-align: top;
    text-align: center;

    padding: 12px;
    background-color: $orange;
    color: $white;
    font-size: 16px;
    line-height: 24px;
    transition: background-color 0.5s ease-out 0.5s;
  }

  &__button {
    width: 249px;
  }

  &__submit-button {
    width: 100%;
  }

  &__button:hover,
  &__button:focus,
  &__submit-button:hover,
  &__submit-button:focus {
    background-color: $red;
  }

  &__modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 416px;
    box-shadow: 0 1px 5px rgba(189, 189, 189, 0.45);
    border-radius: 4px;
    background-color: $white;
    padding: 46px 48px 40px;
    z-index: 5;

    &--hidden {
      display: none;
    }
  }

  &__modal-slogan {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 32px;
  }

  &__close-button {
    @include reset-button;

    width: 15px;
    height: 15px;
    position: absolute;
    top: 22px;
    right: 25px;
  }

  &__close-button::before,
  &__close-button::after {
    position: absolute;
    content: "";
    width: 18px;
    height: 2px;
    background-color: $charcoal;
    top: 6px;
    left: -1px;
  }

  &__close-button::before {
    transform: rotate(-45deg);
  }

  &__close-button::after {
    transform: rotate(45deg);
  }

  &__item {
    &--name {
      margin-bottom: 16px;
    }

    &--phone {
      margin-bottom: 32px;
    }
  }

  &__input {
    width: 100%;
    border: 1px solid $light-charcoal;
    border-radius: 4px;
    padding: 11px 16px 13px;
    font-size: 16px;
    line-height: 24px;
    outline: none;
  }

  &__input:focus {
    border-color: $black;
  }

  &__input::placeholder {
    color: $charcoal;
  }

  &__list {
    @include reset-list;

    border-top: 1px solid $very-light-grey;
    margin-bottom: 32px;

    &--hidden {
      display: none;
    }
  }

  &__person-item {
    padding: 16px 6px 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $very-light-grey;
  }

  &__name,
  &__phone {
    font-size: 16px;
  }

  &__details-wrap {
    max-width: 494px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__remove-person {
    @include reset-button;

    display: block;
    width: 12px;
    height: 12px;
    position: relative;
  }

  &__remove-person::before,
  &__remove-person::after {
    width: 15px;
    height: 2px;
    position: absolute;
    content: "";
    top: 4px;
    left: -1px;
    background-color: $bright-red;
  }

  &__remove-person::before {
    transform: rotate(-45deg);
  }

  &__remove-person::after {
    transform: rotate(45deg);
  }

  @media (max-width: $tablet-width) {
    padding-left: 24px;
    padding-right: 39px;

    &__details-wrap {
      width: 484px;
    }

    &__person-item {
      padding-right: 10px;
    }
  }

  @media (max-width: $mobile-width) {
    padding-left: 20px;
    padding-right: 20px;

    &__modal {
      width: 100%;
      max-width: 320px;
      padding: 46px 20px 40px;
    }

    &__details-wrap {
      flex-direction: column;
      align-items: flex-start;
      width: auto;
    }

    &__name {
      margin-bottom: 8px;
    }

    &__person-item {
      align-items: flex-start;
      padding-right: 7px;
    }

    &__remove-person {
      margin-top: 5px;
    }
  }
}
