.mobile-control {
  background-color: $white;
  box-shadow: 0 1px 5px rgba(189, 189, 189, 0.35);
  width: 100%;
  padding: 18px 20px 64px;
  margin-top: 16px;

  &__wrap-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #f57e00;
    margin: 1rem;
    padding: 1rem;
    border-radius: 8px;
    gap: 10px;
    background: white;
    width: 200px;
    cursor: pointer;

    span {
      font-size: 18px;
    }
  }

  &__button {
    @include reset-button;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 144px;
    height: 144px;
    border-radius: 50%;
    background-image: linear-gradient(323.71deg, #f57e00 13.68%, rgba(245, 126, 0, 0.63) 86.71%);
    box-shadow: 0 0 15px rgba(189, 189, 189, 0.55);

    &--closed {
      .mobile-control__closed-lock-icon {
        display: block;
      }

      .mobile-control__opened-lock-icon {
        display: none;
      }
    }

    &--opened {
      .mobile-control__closed-lock-icon {
        display: none;
      }

      .mobile-control__opened-lock-icon {
        display: block;
      }
    }
  }

  &__list {
    @include reset-list;
  }

  &__action {
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 8px;
  }

  &__slogan {
    font-weight: 300;
    font-size: 30px;
    text-align: center;
    line-height: 36px;
    margin-bottom: 32px;
  }

  &__back-link {
    display: block;
    height: 20px;
    width: 12px;
    color: $orange;
    margin-bottom: 35px;
    transition: color 0.5s ease-out 0.5s;
  }

  &__back-link:hover,
  &__back-link:focus {
    color: $red;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--main {
      margin-bottom: 64px;
    }
  }

  &__close-wrap {
    font-size: 16px;
    color: $charcoal;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;

    &--hidden {
      display: none;
    }
  }
}
