.order-info {
  background-color: $white;
  width: 100%;
  padding: 32px 32px 40px;
  box-shadow: 0 1px 5px rgba(189, 189, 189, 0.35);

  &--hidden {
    display: none;
  }

  &--soon-rent-ending {
    .order-info__soon-rent-note {
      display: inline-block;
      vertical-align: top;
      color: $red;
      font-size: 16px;
    }
  }

  &--overdue {
    .order-info__overdue-notification {
      display: inline-block;
      padding-left: 14px;
      border-left: 2px solid $orange;
      color: $red;
      font-size: 16px;
      font-style: italic;
    }

    .order-info__title {
      margin-bottom: 16px;
    }

    .order-info__title-wrap {
      margin-bottom: 32px;
    }

    .order-info__end-rent-note {
      display: inline;
      color: $red;
      font-size: 16px;
    }

    .order-info__link {
      &--buy-key,
      &--end-rent {
        display: none;
      }

      &--pay-end-rent {
        display: inline-block;
      }
    }

    .order-info__note {
      display: none;
    }

    .order-info__links-wrap {
      margin-bottom: 0;
    }
  }

  &__end-rent-note {
    display: none;
  }

  &__overdue-notification {
    display: none;
  }

  &__title {
    font-size: 19px;
    line-height: 24px;
    color: $black;
    margin: 0;
  }

  &__title-wrap {
    margin-bottom: 24px;
  }

  &__slogan {
    font-size: 14px;
    line-height: 20px;
    color: $charcoal;
    margin-bottom: 8px;
  }

  &__address {
    font-size: 16px;
  }

  &__note {
    line-height: 20px;
    color: $charcoal;
    font-style: italic;
  }

  &__details {
    display: flex;
    margin-bottom: 32px;
  }

  &__size-details {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

  &__item {
    &--properties {
      flex-shrink: 0;
      margin-right: 47px;
    }

    &--address {
      width: 269px;
      margin-right: 42px;
    }
  }

  &__link {
    display: inline-block;
    vertical-align: top;
    padding: 12px 20px;
    font-size: 16px;
    color: $black;
    border-radius: 30px;
    background-color: $pale-grey;
    transition: background-color 0.5s ease-out 0.5s;

    &--prolong {
      background-color: $orange;
      color: $white;
    }

    &--pay-end-rent {
      display: none;
    }
  }

  &__link:hover,
  &__link:focus {
    background-color: $dark-pale-grey;
  }

  &__link--prolong:hover,
  &__link--prolong:focus {
    background-color: $dark-orange;
  }

  &__link:not(:last-of-type) {
    margin-right: 8px;
  }

  &__links-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
  }

  &__start-date {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

  &__soon-rent-note {
    display: none;
  }

  &__terms-wrap {
    font-size: 16px;
  }

  &__details-item-wrap {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__terms-mobile {
    display: none;
  }

  @media (max-width: $tablet-width) {
    display: flex;
    flex-direction: column;
    padding: 32px 24px 39px;
    // position: relative;

    &--hidden {
      display: none;
    }

    &__terms-mobile {
      display: block;
      margin-top: 24px;
    }

    &__title-wrap {
      margin-bottom: 16px;
    }

    &--overdue {
      .order-info__item--terms {
        top: 145px;
      }

      .order-info__links-wrap {
        margin-bottom: 31px;
      }
    }

    &--soon-rent-ending {
      .order-info__title-wrap {
        margin-bottom: 16px;
      }
    }

    &__details {
      flex-direction: column;
      margin-bottom: 0;
      order: 2;
    }

    &__title {
      order: -1;
    }

    &__links-wrap {
      order: 0;
    }

    &__note {
      order: 1;
      margin-bottom: 30px;
    }

    &__item {
      &--properties {
        margin-right: 0;
        order: 0;
      }

      &--address {
        margin-right: 0;
        order: -1;
        width: 505px;
        margin-bottom: 34px;
      }

      &--terms {
        display: none;
      }
    }

    &__terms-wrap {
      display: flex;
      align-items: center;
    }

    &__start-date {
      margin-right: 5px;
    }

    &__end-date {
      margin-right: 3px;
    }
  }

  @media (max-width: $mobile-width) {
    padding: 32px 20px 40px;

    &--overdue {
      .order-info__title-wrap {
        margin-bottom: 16px;
      }

      .order-info__item {
        &--terms {
          top: 216px;
        }
      }
    }

    &--soon-rent-ending {
      .order-info__title-wrap {
        margin-bottom: 16px;
      }
    }

    &__links-wrap {
      flex-direction: column;
      align-items: flex-start;
    }

    &__link:not(:last-of-type) {
      margin-right: 0;
      margin-bottom: 16px;
    }

    &__item {
      &--address {
        width: auto;
      }

      &--terms {
        left: 20px;
      }
    }

    &__terms-wrap {
      flex-wrap: wrap;
      padding-right: 16px;
    }
  }
}
