.profile-form {
  &__input {
    width: 100%;
    outline: none;
    background-color: $white;
    border: 1px solid $light-charcoal;
    border-radius: 4px;
    padding: 14px 16px;
    font-size: 16px;
    color: $black;
  }

  &__input:focus {
    border-color: $black;
  }

  &__input::placeholder {
    color: $charcoal;
  }

  &__error {
    display: none;
  }

  &__item {
    position: relative;

    &--error {
      .profile-form__input {
        border-color: $orange;
      }

      .profile-form__input:focus {
        border-color: $orange;
      }

      .profile-form__error {
        display: inline;
        color: $orange;
        font-size: 12px;
        position: absolute;
        right: 0;
        bottom: -19px;
      }
    }
  }

  &__item:not(:last-of-type) {
    margin-bottom: 16px;
  }

  &__item:last-of-type {
    margin-bottom: 32px;
  }

  &__submit {
    @include reset-button;

    width: 100%;
    background-color: $orange;
    border-radius: 50px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    padding: 15px;
    font-size: 16px;
    color: $white;
    margin-bottom: 32px;
  }

  &__submit:hover,
  &__submit:focus {
    background-color: $red;
  }

  &__submit:disabled {
    color: $half-white;
    cursor: default;
  }

  &__submit:disabled:hover,
  &__submit:disabled:focus {
    background-color: $orange;
  }

  @media (max-width: $tablet-width) {
    &__input {
      box-shadow: inset 0 4px 4px rgba(189, 189, 189, 0.25);
    }
  }
}
