.smart-bookings {
  background-color: $white;
  padding: 32px 32px 40px;

  &__back-link {
    display: none;
  }


  &__link--btn {
    display: inline-block;
    vertical-align: top;
    padding: 12px 20px;
    font-size: 16px;
    color: white;
    border-radius: 30px;
    background-color: #f57e00;
    -webkit-transition: background-color .5s ease-out .5s;
    transition: background-color .5s ease-out .5s;
  }

  &__table__head {
    border-bottom: 1px solid $very-light-grey;
    display: flex;
    font-weight: 400;
    color: #999;
    font-size: 14px;
    line-height: 20px;
    padding: 1rem 0;
    justify-content: space-between;
  }

  &__table {
    display: flex;
    justify-content: space-between;
    padding: 16px 0 15px;
    border-bottom: 1px solid #eee;
    font-size: 16px;

    &__sub-info {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__sub-action {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__id {
    min-width: 30px;
  }

  &__params {
    min-width: 180px;
  }

  &__address, &__date, &__status {
    max-width: 100px;
    min-width: 100px;
  }

  &__date {
    max-width: 120px;
    min-width: 120px;
  }

  &__actions {
    max-width: 100px;
    min-width: 100px;
    display: flex;
    flex-direction: column;
  }

  &__table--close {
    color: #999
  }

  &__title {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;

    margin-bottom: 32px;
  }

  &__nav-link {
    font-size: 19px;
    color: $pale-charcoal;


    &--active {
      color: $black;
    }

    &--charges {
      margin-right: 32px;
    }
  }

  &__links-wrap {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid $very-light-grey;
    margin-bottom: 32px;
  }

  &__table {
    width: 100%;
  }

  &__head-number {
    width: 99px;
    margin-right: 15px;
  }

  &__tbody {
    width: 100%;
  }

  &__head {
    width: 100%;
    padding-bottom: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  @media (max-width: $tablet-width) {
    padding: 16px 23px 40px;

    &__back-link {
      display: flex;
      align-items: center;

      margin-bottom: 32px;
      color: $orange;
      transition: color 0.5s ease-out 0.5s;
      width: 80px;
    }

    &__back-link:hover,
    &__back-link:focus {
      color: $red;
    }

    &__back-link span {
      font-size: 19px;
    }

    &__back-icon {
      margin-right: 13px;
    }

  }

  @media (max-width: $mobile-width) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 580px) {
    padding-left: 20px;
    padding-right: 20px;

    &__table__head {
      display: none;
    }

    &__table {
      flex-direction: column;
      gap: 10px;

    }
  }
}
