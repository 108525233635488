.notice {
  position: relative;
  padding-top:16px;
  padding-bottom:16px;
  padding-left: 21px;
  font-size: 14px;
  background: rgba(235, 87, 87, 0.08);
  border-radius: 4px;
  color: $black;
  line-height: 20px;
  &__text {
    margin-left: 29px;
    &::before {
      position: absolute;
      content: "";
      width: 20px;
      height: 20px;
      background-image: url("../img/icons/notice.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      left: 21px;
    }
  }

  &__href {
    position: relative;
    line-height: 20px;
    color: $orange;
    &::after {
      position: absolute;
      content: "";
      width: 20px;
      height: 20px;
      background-image: url("../img/icons/arrow.svg");
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

}
