@mixin reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin reset-button {
  border: 0;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  outline: none;
}
