.shared {
  &__scroll::-webkit-scrollbar {
    width: 3px;
  }

  &__scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
    border-radius: 10px;
    background-color: #f9f9fd;
  }

  &__scroll::-webkit-scrollbar-thumb {
    background-color: #f57e0096;
    border-radius: 10px;
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .25) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, .25) 50%,
      rgba(255, 255, 255, .25) 75%,
      transparent 75%,
      transparent);
  }

  &__error-border {
    border: 1px solid red;
  }

  &__hr {
    margin: 1rem 0; border-bottom: 1px solid #eee;
  }

  &__checkbox-label--disabled::after, &__checkbox-label--disabled::before {
    background-color: #999 !important;
    border-color: #999 !important;
  }

  &__small_box_scroll {
    margin-bottom: 0.5rem;
    overflow: auto;
    max-height: 100px;
  }

  &__small_text {
    font-size: 12px;
    color: #999;
  }

}
