.list-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $white;

  &__main {
    flex-grow: 1;

    background-color: $white;

    &--order {
      background-color: $very-pale-grey;
    }
  }

  &__wrap {
    padding: 40px 10px;
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;

    &--catalog {
      padding-right: 0;

      padding-left: 0;
    }
  }

  @media (max-width: $tablet-width) {
    &__wrap {
      padding-left: 24px;
      padding-right: 24px;

      &--catalog {
        padding-right: 0;

        padding-left: 0;
      }
    }
  }

  @media (max-width: $mobile-width) {
    &__wrap {
      padding-left: 5.3%;
      padding-right: 5.3%;

      &--catalog {
        padding-right: 0;

        padding-left: 0;
      }
    }
  }
}
