.my-boxes {
  width: 321px;
  background-color: $white;
  box-shadow: 0 1px 5px rgba(189, 189, 189, 0.35);
  margin-right: 16px;
  flex-shrink: 0;

  &--settings {
    .my-boxes__name,
    .my-boxes__address,
    .my-boxes__rent-link,
    .my-boxes__till-pay {
      display: none;
    }

    .my-boxes__rent-box-link {
      display: block;
    }
  }

  &__wrap {
    padding: 32px 32px 30px;
  }

  &__title-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 32px;
  }

  &__title {
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    color: $dark-black;
    margin: 0;
  }

  &__moderation-message {
    &--hidden {
      display: none;
    }

    padding-left: 14px;
    border-left: 2px solid $bright-red;
    color: $bright-red;
    font-size: 16px;
    font-style: italic;
    margin-top: 16px;
  }

  &__list {
    @include reset-list;

    margin-bottom: 32px;
  }

  &__item:not(:last-child) {
    margin-bottom: 16px;
  }

  &__name {
    font-size: 19px;
    margin-bottom: 4px;
    color: $orange;
    transition: color 0.5s ease-out 0.5s;

    &--active {
      color: $black;
      cursor: default;
    }
  }

  &__name:hover,
  &__name:focus {
    color: $red;
  }

  &__name--active:hover,
  &__name--active:focus {
    color: $black;
  }

  &__address {
    line-height: 20px;
    color: $charcoal;
    margin-bottom: 8px;
  }

  &__till-pay {
    line-height: 20px;
    &--red {
      color: $red;
    }
    &--green {
      color: $green;
    }
  }

  &__rent-link,
  &__history-link,
  &__properties-link,
  &__quit-link,
  &__rent-box-link,
  &__name-link {
    font-size: 19px;
    color: $orange;
    transition: color 0.5s ease-out 0.5s;
  }

  &__rent-link:hover,
  &__history-link:hover,
  &__properties-link:hover,
  &__quit-link:hover,
  &__rent-link:focus,
  &__history-link:focus,
  &__properties-link:focus,
  &__quit-link:focus,
  &__rent-box-link:hover,
  &__rent-box-link:focus,
  &__name-link:hover,
  &__name-link:focus {
    color: $red;
  }

  &__link {
    &--current {
      color: $black;
    }
  }

  &__link--current:hover,
  &__link--current:focus {
    color: $black;
  }

  &__links-wrap {
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-top: 1px solid $very-light-grey;
    border-bottom: 1px solid $very-light-grey;
  }

  &__history-link {
    margin-bottom: 32px;
  }

  &__quit-link {
    display: inline-block;
    vertical-align: top;
    margin: 32px;
  }

  &__rent-box-link {
    display: none;
  }

  &__prolongation,
  &__pay-rent,
  &__remove-rent {
    border-radius: 30px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    font-size: 14px;
    line-height: 20px;
    color: $black;
    border: 1px solid $charcoal;
    padding: 6px;
    margin-right: 8px;
    margin-bottom: 12px;

    flex-shrink: 0;

    &--hidden {
      display: none;
    }
  }

  &__prolongation {
    width: 146px;
  }

  &__pay-rent {
    width: 146px;
  }

  &__remove-rent {
    width: 87px;
  }

  &__links-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  @media (max-width: $tablet-width) {
    display: none;

    &__name-link {
      display: inline-block;
      vertical-align: top;
      margin-bottom: 3px;
    }

    &--mobile {
      display: block;
      margin-top: 16px;
      background-color: transparent;
      width: 100%;
      box-shadow: none;

      .my-boxes__wrap {
        background-color: $white;
        box-shadow: 0 1px 5px rgba(189, 189, 189, 0.35);
        margin-bottom: 16px;
        padding-right: 24px;
        padding-left: 24px;
      }

      .my-boxes__mobile-wrap {
        background-color: $white;
        box-shadow: 0 1px 5px rgba(189, 189, 189, 0.35);
      }

      .my-boxes__mobile-list {
        @include reset-list;
      }

      .my-boxes__links-wrap {
        border-bottom: 0;
        padding-right: 24px;
        padding-left: 24px;
      }

      .my-boxes__quit-link {
        margin-top: 0;
        margin-left: 24px;
      }

      .my-boxes__rent-box-link {
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  @media (max-width: $mobile-width) {
    &--mobile {
      .my-boxes__links-wrap,
      .my-boxes__wrap {
        padding-right: 20px;
        padding-left: 20px;
      }

      .my-boxes__quit-link {
        margin-left: 20px;
      }
    }
  }
}
